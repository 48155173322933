.share-page {
  .copy-text-button {
    padding: 6px 12px;
    color: #858993;
    border-color: #858993;
    &:hover {
      color: #6C6F7A;
      border-color: #6C6F7A;
      background: whitesmoke;
    }
  }

  .codeExample {
    pre {
      padding: 10px;
      font-size: 11px;
      margin: 0;
      max-width: fit-content;
      border: 1px solid #eee;
      background-color: #fafafa;
    }
  }
}