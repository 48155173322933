@import '../../Colours.scss';

.session-replay {
  main {
    height: calc(100vh - 48.5px);
  }

  .nav-wrapper {
    background-color: unset;
    box-shadow: none;
  }

  .btn-style-removed {
    all: unset;
    cursor: pointer;
    &:focus {
      outline: revert;
    }
    &:active, &.btn-primary:not(:disabled):not(.disabled):active {
      all: unset;
    }
    &:hover {
      color: #474849;
    }
  }

  .info-circle-icon {
    cursor: pointer;
    color: #a291ca;
    transition: all .2s ease-in-out;
    &:hover {
      color: #6950a5;
      transform: scale(1.1);
    }
  }

  .view-replay-cta {
    background-color: #6950a5;
    border-color: #4f3d7f;
    &:active, &:focus, &:hover, &:not(:disabled):not(.disabled):active {
      background-color: #423267;
      border-color: #4f3d7f;
    }
  }

  .progress-area {
    display: flex;
    align-items: center;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .form-check-input {
    &:not(:checked) {
      border-color: $title_text_grey;
    }
  }

  .card {
    .card-body {
      min-height: 356px;
      padding: 20px 1.5rem 20px 1.5rem;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
        }
      }
      .card-content {
        flex-grow: 1;
        form {
          flex-grow: 1;
        }
      }
    }
  }
}