@import '../Colours.scss';

#org-details-form {
  .form-actions-row {
    padding-top: 15px;
  }

  .invalid-input {
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  .invalid-input-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  input[type=checkbox] {
    filter: hue-rotate(18deg); // Change the native colour of the checkbox
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-top: 0;
  }
}
