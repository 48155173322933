@import '../../Colours.scss';

#headlines {
  background: linear-gradient(to right, #271e3f, #6950a5);
  border-radius: 15px;
  color: white;
  --bs-heading-color: white;
  .card-title {
    font-weight: 400;
    color: white;
  }
  .card-body {
    padding: 20px 1.5rem 20px 1.5rem;
  }

  .headlines-inner-card {
    border-radius: 15px;
    box-shadow: 0px 0px 5px 1px #7c64b4;
    .card-body {
      padding: 10px 1.5rem 10px 1.5rem;
    }

    .card-title {
      color: unset;
      font-weight: 300;
    }
    transition: all .2s ease-in-out;
    &:hover {
      box-shadow: 0px 0px 6px 4px #7c64b4;
    }

    .quick-tip-btn {
      margin-right: 0;
    }
  }

  .headlines-card-grid {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, max(250px, 49%)), 1fr));

    .row-span-2 {
      grid-row: auto/span 2;
    }
  }


  .headlines-stats-grid {
    font-size: 1.1rem;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: auto fit-content(35px) fit-content(25px);

    .col-span-3 {
      grid-column: auto/span 3;
    }
  }

  .arrow-up {
    rotate: 270deg;
  }

  .arrow-down {
    rotate: 90deg;
  }

  .arrow-green {
    color: #1E8F6B;
  }

  .arrow-red {
    color: #DB3A62;
  }

  .arrow-icon {
    &.fade-in {
      animation: fadeIn 500ms ease-in;
    }
  }

  .strong-text {
    font-weight: 500;
    font-size: 110%;

    &.fade-in {
      animation: fadeIn 500ms ease-in;
    }
  }

  @keyframes fadeIn {
    from { opacity: 0.2; }
    to { opacity: 1; }
  }

  .previous-stat {
    font-size: 92%;
    color: #737582;
    .strong-text {
      font-size: 100%;
    }
  }

  .loading-placeholder {
    height: 1.3rem;
    vertical-align: middle;
    width: 70px;
    background-color: #e6e6e6;
    border-radius: 2px;
  }

  .skeleton-background {
    animation: skeleton-background-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-background-loading {
    0% {
      background-color: #f9fafb;
    }
    100% {
      background-color: #e1e7ea;
    }
  }
  .skeleton-color {
    animation: skeleton-color-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-color-loading {
    0% {
      color: #e1e7ea;
    }
    100% {
      color: #6C6F7A;
    }
  }

  .text-more-info {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 3px;
    cursor: pointer;
  }

  .headline-field-label {
    font-size: 1.2rem;
  }
}

#html-attributes-popover, #info-popover {
  font-size: 1.1rem;
  .popover-body {
    color: #6C6F7A;
  }
}

#page-link-tooltip {
  --bs-tooltip-max-width: 300px;
  --bs-tooltip-opacity: 0.95;
  .tooltip-inner {
    background-color: white;
    border: 1px solid #6950a5;
    color: $title_text_grey;
  }
  .tooltip-arrow::before {
    border-top-color: #6950a5;
  }
}