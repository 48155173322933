@import '../Colours.scss';

.profile-edit {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .alert-danger {
    .alert-text {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .profile-edit-card {
    margin-bottom: 2.2rem;
  }

  .card-body {
    padding: 20px 2.2rem 2rem 2.2rem;
  }

  .card-title-row {
    // To spread the underline across the top of the card
    margin-left: -2.2rem;
    padding-left: 2.2rem;
    margin-right: -2.2rem;
    padding-right: 2.2rem;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebedf2;

    .card-title {
      font-size: x-large;
      color: #575962;
    }

    #card-header-nav-tabs {
      margin-right: 0;
      .nav-item {
        padding-right: 30px;
        padding-left: 5px;
        margin-bottom: 0;
        @media(max-width: 520px) {
          padding-right: 5px;
        }

        border-bottom: 1px solid #ebedf2;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        .nav-link {
          border: none;
          padding: 0 0 8px 0;
          margin-bottom: -1px;
          color: #7b7e8a;
          font-size: 1.1rem;
          &:hover {
              color: #3f4047;
              border-bottom: 1px solid #3f4047;
          }
          &.active {
            color: #3f4047;
            border-bottom: 1px solid #3f4047;
          }
        }
      }
    }
  }

  .card-content {
    padding-top: 15px;
  }

  form {
    .api-key-text {
      &.api-key-hidden {
        height: 33.5px;
        font-size: 110%;
        font-weight: 700;
        line-height: 21px;
      }
    }

    .form-group {
      .valid-input-icon {
        color: #64ba7f;
        opacity: 0;
        margin-right: -32px;
        margin-left: 4px;
      }

      &.valid {
        .valid-input-icon {
          animation: FadeIn 0.2s linear forwards;
          @keyframes FadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        }

        .form-control.is-valid, .form-control:valid {
          border: 1px solid #64ba7f;
        }
      }
    }
    .invalid-input {
      border: 1px solid #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
    .invalid-feedback, .valid-feedback {
      font-size: 85%;
      margin-bottom: -10px;
      display: block;
      min-height: 16.25px; // Allows for a row for an error message without moving other inputs
    }

    .feedback-info {
      color: #9e97aa;
    }

    .form-check-label {
      padding-left: 5px;
    }

    .form-control[readonly] {
      background-color: whitesmoke;
      color: #575962;
      border-color: #ebedf2;
      &:focus {
        border-color: #ebedf2;
        box-shadow: none;
      }
    }

    .api-key-copy-container {
      color: #91899f;
      border-radius: 0;
      border-right: none;
      border-top-color: #ebedf0;
      border-bottom-color: #ebedf0;
      background-color: whitesmoke;
      border-left: 0;
    }

    .password-show-icon-container {
      color: #91899f;
      background-color: whitesmoke;
      border-color: #ebedf0;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.change-password {
      .password-show-icon-container {
        background-color: white;
        border-color: #ced4da;
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .form-title-row {
      line-height: 18px;
      color: $text_grey;
      padding-top: 15px;
      padding-bottom: 15px;
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0;
      }
    }
  }
  .alert-outline-success, .alert-outline-danger {
    .alert-text {
      padding: 9px 15px;
    }
  }
}

.mfa-modal {
  color: $text_grey;
  .modal-title {
   color: #6C6F7A;
  }
  .modal-footer {
    margin-top: 1rem;
    padding: 1rem 0 0 0;
    margin-right: -1rem;
    margin-left: -1rem;
  }
  &.mfa-setup .mfa-step-content {
    min-height: 330px;

    .alert-text {
      padding: 9px 15px;
    }
  }
  .qr-code-container {
    width: 202px;
    height: 202px;
  }
  .verification-code {
    font-size: 1.5rem;
    font-weight: 500;
    width: unset; // Allow the html size attr to set the width
    border-color: #b1bac4;
  }
 .invalid-input {
    border: 1px solid #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
  .invalid-feedback, .valid-feedback {
    font-size: 85%;
    margin-bottom: -10px;
    display: block;
    min-height: 16.25px; // Allows for a row for an error message without moving other inputs
  }
  .form-control::placeholder {
    opacity: 0.6;
  }
  .password-show-icon-container {
    background-color: white;
    border-color: #ced4da;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

#copy-text-tooltip {
  .tooltip-inner {
    background-color: #5e6cff;
  }
  .tooltip-arrow::before {
    border-top-color: #5e6cff;
  }
}
