@import '../Colours.scss';

#form-segment-comparison-page {
    // Copies of the charts to fix the height/width for PDF export
    #segment-comparison-chart-copy, #segment-comparison-line-chart-copy {
      display: none !important;
    }

    .pdf-export-icon {
      margin-right: 10px;
    }

  .main-content {
    padding-top: 10px;
  }

  .error-message {
    color: #e01f46;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .page-info-card {
    margin: 10px 2rem 10px 2rem;
    right: 0;
    box-shadow: 0 0 15px 4px rgba(113,106,202,.2);
    position: absolute;
    background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
    padding: 2px;
    .card-contents {
      background-color: white;
      max-height: 550px;
      padding: 20px 2rem 20px 2rem;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
          .card-title {
            margin-bottom: 0;
            font-weight: 200;
            color: #575962;
          }
        }
      }
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .text-content {
        overflow-y: auto;
      }

      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
        h4 {
          font-weight: 500;
          font-size: 1.5rem;
        }
      }
    }

    &.open {
      z-index: 4;
      animation: SegmentComparisonFadeIn 0.3s linear forwards;
    }
    &.closed {
      z-index: -1;
      animation: SegmentComparisonFadeOut 0.2s ease-out forwards;
    }

    #Segment-Comparison-chart, #Segment-Comparison-segments, #Segment-Comparison-line-chart, #Segment-Comparison-export {
      height: auto;
      max-width: 350px;
    }

    #Segment-Comparison-chart, #Segment-Comparison-line-chart, #Segment-Comparison-segments  {
      width: 50%;
    }

    #Segment-Comparison-export {
      width: 20%;
    }

    #Segment-Comparison-line-chart, #Segment-Comparison-export {
      vertical-align: top;
    }
  }

  .line-chart-card {
    width: 103%;
    margin-top: -80px;
    box-shadow: 0 0 15px 1px rgba(113,106,202,.2);
    position: absolute;
    .card-contents {
      background-color: white;
      padding: 1.25rem 1.75rem;
      min-height: 563px;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
          .card-title {
            margin-bottom: 0;
            font-weight: 200;
            color: #575962;
          }
        }
      }
      display: flex;
      flex-direction: column;

      .progress-area {
        height: 400px;
      }
    }

    &.open {
      z-index: 4;
      animation: SegmentComparisonFadeIn 0.2s linear forwards;
    }
    &.closed {
      z-index: -1;
      animation: SegmentComparisonFadeOut 0.1s ease-out forwards;
    }

    .metric-selector-item {
      &:hover {
        label {
          text-decoration: underline;
        }
      }
      &.selected {
        label {
          text-decoration: underline;
          font-weight: 500;
        }
      }
    }

    .granularity-buttons {
      color: $dark_text_grey;

      label {
        text-transform: capitalize;
      }
      p {
        margin: 0 0.75rem 0 0.5rem;
      }
      .form-label {
        margin-bottom: 0.25rem;
      }
      &.line-chart-loading {
        pointer-events: none;
      }
    }

    #metric-select {
      min-width: 250px;
    }

    .line-chart-loading {
      pointer-events: none;
    }
  }

  @keyframes SegmentComparisonFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes SegmentComparisonFadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .btn-less-padding {
    padding: 7px 15px;
  }

  .original-content {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &.background {
      opacity: 0.3;
    }
  }

  .csv-export-icon {
    margin-right: 5px;
  }

  .url-copy-icon {
    margin-right: 5px;
  }

  .info-circle-icon {
    max-width: fit-content;
    cursor: pointer;
    color: #a291ca;
    transition: all .2s ease-in-out;
    &:hover {
      color: #6950a5;
      transform: scale(1.1);
    }
  }

  .card-tooltip {
    max-width: fit-content;
    color: $text_grey;
    &:hover {
      color: #3f4047;
    }
  }

  .add-comparison-link {
    cursor: pointer;
    padding-left: 10px;
    color: #5867dd;
    &:hover {
      color: #2739c1;
    }
  }

  .comparison-icon {
    i {
      padding: 5px;
      font-size: 80%;
    }
  }

  #comparison-name {
    width: inherit;
    height: inherit;
    min-width: 181px;
    padding: 1px 4px;
    font-size: 19px;
    line-height: 1;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    color: $dark_text_grey;

    &.invalid-input {
      border-color: #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }

  #comparison-title {
    button {
      padding: 3px 10px;
    }
  }

  .card {
    margin-bottom: 30px;
    &.full-height {
      min-height: 1290px;
    }
    &.half-height {
      min-height: 790px;
    }
  }

  .progress-area {
    display: flex;
    align-items: center;
    height: 428px;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  #metric-select-area {
    margin-top: 20px;
    margin-left: 50px;
  }

  .metric-selector-item {
    display: inline-block;
    position: relative;
    padding-left: 10px;

    &:not(.selected) {
      opacity: 0.6;
    }

    &.selected {
      label {
        color: $dark_text_grey;
      }
    }
    &:hover {
      color: $dark_text_grey;
      opacity: 1;
    }

    input[type=radio] {
      visibility: hidden;
      position: absolute;
    }

    label {
      display: inline-block;
      font-size: 13.5px;
      font-weight: 400;
      color: $title_text_grey;
      cursor: pointer;
    }

    .metric-select-colour-bar {
      display: inline-block;
      width: 20px;
      height: 10px;
      margin-right: 8px;
   }
  }

  #segment-comparison-chart-wrapper {
    // Add padding to allow the final segment-add column
    padding-right: 230px + 8px;
    text-align: center;
  }

  #segment-comparison-data {
    overflow-x: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  #scrollable-comparison-container {
    min-height: 500px;
  }

  #draggable-segments-container {
    // Disable text selection as segments are allowed to be drag and dropped and this sometimes is interpreted as
    // selecting text and flashes https://gitlab.com/Zuko.io/zuko-react-components/merge_requests/10#note_108834307
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }

  #segments-area {
    color: #3f4047;
    .column {
      min-width: 230px;
      max-width: 230px;
    }
    .segment {
      margin: 10px 25px 10px 0;
    }

    .segment, .add-segment {
      box-shadow: 0 1px 8px 1px rgba(150,150,150,0.3);
      border-radius: 5px;
      border: none;
      padding: 10px;
      width: 100%;
    }

    .add-segment {
      margin: 10px 10px 10px 0;
      height: max-content;
    }

    .first-segment {
      margin-left: 74px;
    }

    .segment-config {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed grey;

      .edit-icons-area {
        display: flex;
        justify-content: space-between;
        .segment-icon {
          opacity: 0.5;
          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
        .segment-icon, .drag-icon {
          margin: 0px 5px 0 5px;
        }

        .drag-icon {
          opacity: 0.4;
          pointer-events: auto !important;
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
          &:hover {
            cursor: pointer;
            opacity: 1;
          }
        }
      }

      .config-area {
        .set-config {
          min-height: 160px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      p {
        margin: 0;
      }

      h5 {
        font-size: 13.5px;
        margin: 20px 0 5px 0;
        font-weight: 400;
      }

      .formName {
        font-weight: 500;
        font-size: 17px;
      }

      .segmentConfigFilterList {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        .segmentConfigFilterItem {
          text-align: center;
          min-height: 20px;
          margin-bottom: 5px;
        }
      }

      .attribute-key-value-label {
        max-width: 100%;
        border-radius: 3px;
        letter-spacing: .6px;
        padding: 1px 10px;
        background-color: rgba(143, 137, 214, 0.3);
        font-size: 11.2px;
        line-height: 20px;
        min-height: 20px;
        vertical-align: middle;
        text-align: center;
        display: inline-block;
        color: rgb(68, 68, 68);
      }
    }

    .segment-stats {
      text-align: center;
      .metric-title {
        font-size: 13.5px;
        margin: 20px 0 5px 0;
        font-weight: 400;
      }

      .metric {
        font-weight: 500;
        font-size: 17px;
      }
    }
  }

  .add-segment {
    margin: 10px 0;
  }

  .edit-segment, .add-segment {
    max-width: 325px;
    margin: 10px;
    .edit-segment-form {
      form {
        margin-bottom: 14px;
      }
      .select-label {
        text-align: left;
        display: block;
        margin: 20px 0 5px 0;
        font-size: 13.5px;
        font-weight: 400;
        line-height: 14.85px;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  #edit-segment-buttons {
    button {
      padding: 3px 10px;
      &.load:disabled {
        background-color: #f6f5fa;
        border-color: #f6f5fa;
      }
      &.cancel:disabled {
        background-color: #f6f5fa;
        border-color: #f6f5fa;
      }
    }
  }
}
