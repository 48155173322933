.page-feedback-row {
  .unstyled-btn {
    margin: 0;
    padding: 0;
    background-color: inherit;
    border: 0;
    color: inherit;
    cursor: pointer;
    &:hover, &.is-open {
      color: #3f4047;
    }
  }
}
