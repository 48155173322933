@import '../../Colours.scss';

.field-config {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .title-row {
    margin-bottom: 30px;
    min-height: 39px;
    #form-title {
      padding: 7px 25px 7px 0;
      margin-bottom: 0;
    }
  }

  .card {
    margin-bottom: 20px;
  }

  .card-body {
    padding: 20px 2.2rem 2rem 2.2rem;
  }

  .card-title-row {
    // To spread the underline across the top of the card
    margin-left: -2.2rem;
    padding-left: 2.2rem;
    margin-right: -2.2rem;
    padding-right: 2.2rem;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebedf2;

    .card-title {
      font-size: x-large;
      color: #575962;
    }

    #card-header-nav-tabs {
      margin-right: 0;
      .nav-item {
        padding-right: 30px;
        padding-left: 5px;
        margin-bottom: 0;

        border-bottom: 1px solid #ebedf2;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        .nav-link {
          border: none;
          padding: 0 0 8px 0;
          margin-bottom: -1px;
          color: $text_grey;
          font-size: 1.1rem;
          &:hover {
              color: #3f4047;
              border-bottom: 1px solid #3f4047;
          }
          &.active {
            color: #3f4047;
            border-bottom: 1px solid #3f4047;
          }
        }
      }
    }
  }

  .tab-content {
    width: 100%;
  }

  .card-content {
    padding: 2.2rem 0 0 0;
  }

  #form-load-error {
    text-align: center;
    h3 {
      font-size: 1.2rem;
    }
  }

  .field-config-accordion {
    --bs-accordion-border-radius: 0.25rem;
    .accordion-button {
      color: #575962;
      padding: 0.3rem 0.25rem 0.3rem 10px;
      background-color: rgba(0, 0, 0, 0.03);
    }

    .accordion-header {
      line-height: 18px;
      color: #575962;
      &:hover {
        color: #575962;
        svg {
          color: #575962;
        }
      }
      &.accordion-open {
        color: #575962;
        svg {
          color: #575962;
        }
      }
    }
    .accordion-body {
      padding: 10px 15px 15px 15px;
      color: $title_text_grey;
      h4 {
        font-weight: 300;
        font-size: 18px;
      }
    }
    ul {
      padding-left: 20px;
    }
  }

  i.info {
    text-align: right;
    margin-left: 5px;
    font-size: 18px;
  }

  #unconfiguredFieldsArea, #newFieldMergingRuleArea, #configuredFieldsArea {
    margin-top: 2.2rem;
  }

  #unconfiguredFieldsArea {
    .table-search-row {
      input {
        width: 100%;
        max-width: 160px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
      }
      button {
        &:disabled {
          background-color: #00c5dc;
          border-color: #00c5dc;
        }
      }
    }

    table {
      border-bottom: none !important;
      margin-top: 1rem;
      color: $title_text_grey;
      display: inline-block;
      overflow-y: auto;
      white-space: nowrap;
      thead {
        font-size: 13px;
        th {
          font-weight: 500;
          border-bottom: none;
          padding: 0.75rem;
        }
        background-color: whitesmoke;
        .table-sort-icons {
          cursor: pointer;
          padding-left: 4px;
          font-size: 1.1rem;
          color: #dcdcdc;
          .sorted {
            color: #7a80dd
          }
        }
      }
      .tagname-column, .type-column {
        width: 1%;
      }
      tbody {
        font-size: 12px;
        tr:nth-of-type(odd) {
          --bs-table-striped-color: #575962;
          &:hover {
            .add-column {
              color: #212529;
            }
          }
        }
        tr:nth-of-type(even) {
          .add-column {
            --bs-table-striped-bg: white;
            background-color: white;
          }
          &:hover {
            .add-column {
              --bs-table-accent-bg: #ececec;
              background-color: #ececec;
            }
          }
          &:hover {
            --bs-table-accent-bg: #ececec;
          }
        }
      }
      th.name-column, th.id-column {
        width: 28%;
        min-width: 100px;
      }
      td.name-column, td.id-column {
        width: 28%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 0;
        &.label-empty {
          cursor: pointer;
        }

        &.expand {
          max-width: unset;
        }
      }
      .expand-column-icon {
        cursor: pointer;
        color: #a0a3ab;
        svg {
          transform: rotate(45deg);
          &:hover {
            color: #3f4047;
          }
        }
      }
      td {
        border-top: none;
      }
    }

    .add-column {
      width: 1%; // With this enabled the table is 100% width, evenly distributed and adjusted to contents
      position: sticky;
      right: 0;
      background-color: whitesmoke;
    }
    td.add-column {
      cursor: pointer;
    }
    .last-tracked-column {
      padding-left: 20px;
    }
  }

  #newFieldMergingRuleArea {
    font-size: 14px;
    line-height: 18px;
    color: $title_text_grey;
    #merging-rule-info {
      margin: 10px auto;
    }

    .merging-rule-control {
      display: inline-block;
      height: inherit;
      padding: 1px 2px;

      &.form-select {
        width: 70px;
      }
      &.form-control {
        width: 160px;
      }
    }
    #stageFieldMergingRule {
      font-size: 16px;
      margin-left: 5px;
      cursor: pointer;
    }

    code {
      background: #ebedf2;
      padding: .2rem .4rem;
      font-size: 90%;
      color: #bd4147;
      border-radius: .25rem;
    }
  }

  #configuredFieldsArea {
    .table-responsive {
      max-height: calc(100vh - 220px);
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, .5);
      }
    }
    table {
      thead {
        th {
          border-bottom: none;
          padding: 0.75rem;
        }
      }
      .odd {
        background-color: #f9f9f9;
        td {
          border-top: 1px solid #f9f9f9;
        }
        td:first-of-type {
          border-left: 2px solid #f9f9f9;
        }
        td:last-of-type {
          border-right: 2px solid #f9f9f9;
        }
        &:hover {
          td: {
            border-color: whitesmoke;
          }
          --bs-table-accent-bg: whitesmoke;
        }
      }

      .even {
        td {
          border-top: 1px solid white;
        }
        td:first-of-type {
          border-left: 2px solid white;
        }
        td:last-of-type {
          border-right: 2px solid white;
        }
      }
      white-space: nowrap;
      color: $title_text_grey;
      thead {
        font-size: 13px;
        position: sticky;
        top: 0;
        z-index: 2;
        th {
          font-weight: 500;
        }
        background-color: whitesmoke;
      }
      tbody {
        font-size: 12px;
        td {
          vertical-align: middle;
        }
        input:not([type=checkbox]) {
          width: 100%;
          height: 32px;
          border-radius: 3.5px;
          margin: 0;
          border: 1px solid #ebedf2;
          padding: 8px;
        }
      }
      .grip-column {
        width: fit-content;
        padding: 8px 0px 8px 5px;
      }
      .label-column {
        min-width: 200px;
        max-width: 240px;
      }
      .field-order {
        width: fit-content;
      }
      .tagname-column, .type-column {
        width: fit-content;
      }
      th.name-column, th.id-column {
        width: 28%;
        min-width: 100px;
      }
      td.name-column, td.id-column {
        width: 28%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 0;
        &.label-empty {
          cursor: pointer;
        }

        &.expand {
          max-width: unset;
        }
      }
      .expand-column-icon {
        cursor: pointer;
        color: #a0a3ab;
        svg {
          transform: rotate(45deg);
          &:hover {
            color: #3f4047;
          }
        }
      }
      .column-info-icon {
        vertical-align: top;
        margin-left: 2px;
      }
      .field-row {
        &.matched-merged-showing {
          td, td:first-of-type,  td:last-of-type {
            border-color: #e3e3e3;
            transition: border-color 100ms ease-in;
          }
        }
      }
      .clickable {
        transition: all .2s ease-in-out;
        cursor: pointer;
        color: #5867dd;
        font-size: 14px;
        font-weight: 500;
        &:hover {
          text-decoration: underline;
          color: #5867dd;
        }
      }
    }

    .manage-hidden-checkbox {
      input[type=checkbox] {
        filter: hue-rotate(18deg); // Change the native colour of the checkbox
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-right: 3px;
        margin-bottom: 3px;
      }
    }


    .hide-column {
      .form-check-input:checked {
        background-color: #abb4f0;
        border-color: #abb4f0;
      }
    }

    .hidden-field-row {
      transition: all .2s ease-in-out;
      opacity: 0.6;
    }

    .disabled-drag {
      color: #9e9e9e;
      pointer-events: none;
    }

    .success-tick-icon {
      color: #64ba7f;
    }

    .error-message {
      color: #e01f46;
    }

    .invalid-input {
      border-color: #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }

    .invalid-input-feedback {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    .mergedDisabled {
      color: #9e9e9e;
      font-size: 80%;
      margin: 0;
    }
  }

  .no-fields-msg {
    color: $title_text_grey;
  }

  .unsaved-msg, .saved-msg {
    .alert {
      margin-bottom: 0;
    }
    .alert-text {
      padding: 0 5px;
    }
  }

  #session-info-alert {
    margin-top: 8px;
    .alert {
      margin-bottom: 0;
    }
  }

  #matched-merged-fields-table {
    z-index: 1; // To go underneath the table header
    position: sticky;
    thead {
      border-bottom: 2px solid darkgray;
    }
    tr {
      background-color: white !important;
      border-bottom: 1px solid #dee2e6;
      &:hover {
        color: inherit
      }
    }
  }

  .matched-merged-fields-row {
    min-height: 40px;
    .matched-merged-fields-cell {
      border-left: 2px solid white; // The default dark border is shown first without this initial color
      border-right: 2px solid white;
      border-bottom: 1px solid white;
      &.matched-merged-showing {
        border-color: #e3e3e3;
     }
    }
    .matched-merged-fields-cell {
      font-size: 1rem;
      color: $text_grey;
      h4 {
        font-weight: 300;
        font-size: 18px;
      }
      h5 {
        font-size: 1.1rem;
      }
    }
    &:hover {
      color: inherit;
      background-color: inherit;
      --bs-table-hover-bg: white;
    }
    .pagination {
      font-size: 14px;
    }
  }

  .page-item.active .page-link {
    background-color: #5867dd;
    border-color: #5867dd;
  }
  .page-item:not(.disabled):not(.active) .page-link {
    color: #5867dd;
  }
}

#hide-field-popover {
  .popover-body {
    max-width: 300px;
    text-align: left;
  }
}

#matched-fields-popover {
  .popover-body {
    max-width: 400px;
    text-align: left;
  }
}

  // Row slide down transitions
  .slide-enter {
    opacity: 0;
    transform: translateY(-8%);
  }
  .slide-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 300ms ease-in, transform 200ms;
  }

  .slide-exit {
    opacity: 1;
  }
  .slide-exit-active {
    opacity: 0;
    transform: translateY(-5%);
    transition: opacity 200ms ease-out, transform 100ms;
  }
