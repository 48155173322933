@import '../../Colours.scss';

#builder-publishing-modal {
  min-width: 500px;
  color: $title_text_grey;
  h3 {
    font-weight: 300;
    margin-bottom: 0;
  }
  .modal-content {
    min-height: 200px;
  }

  .copy-text-button {
    padding: 6px 12px;
    color: #858993;
    border-color: #858993;
    &:hover {
      color: #6C6F7A;
      border-color: #6C6F7A;
      background: whitesmoke;
    }
  }

  .alert-outline-success {
    .alert-text {
      padding: 4px 8px;
    }
  }
}