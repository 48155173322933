@import '../../Colours.scss';

.form-integrations {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }

    margin-bottom: 20px;
  }

  .title-row {
    margin-bottom: 30px;
    min-height: 39px;
    #form-title {
      padding: 7px 25px 7px 0;
      margin-bottom: 0;
    }
  }

  .card-body {
    padding: 20px 2.2rem 2rem 2.2rem;
  }

  .card-title-row {
    // To spread the underline across the top of the card
    margin-left: -2.2rem;
    padding-left: 2.2rem;
    margin-right: -2.2rem;
    padding-right: 2.2rem;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebedf2;

    .card-title {
      font-size: x-large;
      color: #575962;
    }

    #card-header-nav-tabs {
      margin-right: 0;
      .nav-item {
        padding-right: 30px;
        padding-left: 5px;
        margin-bottom: 0;

        border-bottom: 1px solid #ebedf2;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        .nav-link {
          border: none;
          padding: 0 0 8px 0;
          margin-bottom: -1px;
          color: $text_grey;
          font-size: 1.1rem;
          &:hover {
              color: #3f4047;
              border-bottom: 1px solid #3f4047;
          }
          &.active {
            color: #3f4047;
            border-bottom: 1px solid #3f4047;
          }
        }
      }
    }
  }

  .card-tagline {
    font-weight: 400;
    font-size: 1.1rem;
  }

  .tab-content {
    width: 100%;
  }

  .card-content {
    padding: 1rem 0 0 0;
  }

  #form-load-error {
    text-align: center;
    h3 {
      font-size: 1.2rem;
    }
  }

  color: $title_text_grey;

  .valid-input-feedback {
    color: green;
  }
}
