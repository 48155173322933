@import '../Colours.scss';

.integrations-page {
  h4 {
    font-weight: 500;
    font-size: 1.3rem;
  }
  .form-control[readonly] {
    background-color: whitesmoke;
    color: #575962;
    border-color: #ebedf2;
    &:focus {
      border-color: #ebedf2;
      box-shadow: none;
    }
  }
  .text-copy-container {
    color: #91899f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #ebedf0;
    background-color: whitesmoke;
    border-left: 0;
  }

  .value-info {
    color: #575962;
  }

  .input-group {
    max-width: 340px;
  }

  form {
    .successful, .failed {
      color: green !important;
    }

    .failed {
      color: red !important;
    }
  }
}
