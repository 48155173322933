@import '../Colours.scss';

#session-replay-cta-modal {
  min-width: 500px;
  color: $title_text_grey;
  h3 {
    font-weight: 300;
    margin-bottom: 0;
  }
  .modal-content {
    min-height: 200px;
  }
}