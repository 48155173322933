#replay-sessions-table {
  .table-body tr {
    height: unset
  }

  .row > * {
    width: unset;
    max-width: unset;
  }


  .header-text-with-sort {
    margin-left: 18px;
  }
  .table-sort-icons {
    font-size: 1.1rem;
    .sort-up {
      margin-bottom: -0.5rem;
    }
    .sort-down {
      margin-top: -0.5rem;
    }
    .sort-up, .sort-down {
      color:#dcdcdc;
      line-height: 0.5rem;
      &.sorted {
        color: #7a80dd;
      }
    }
  }

  .table-wrap {
    overflow: scroll;
    border: 1px solid #ebedf3;
    border-radius: 0.25rem;
    padding-left: 8px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  table {
    --bs-table-hover-bg: #fafafa;

    thead {
      position: sticky;
      top: 0;
      z-index: 3;
    }
    tr {
      height: fit-content;
      border-bottom: 1px solid #ebedf3;
    }
    tr > th {
      white-space: unset;
      word-break: break-word;
    }
    th {
      font-size: 15px;
      background-color: #fff;
      padding: 0.5rem;
      margin: 0px 3px;
      .header-text-with-sort {
        margin-left: 18px;
      }
      &:first-of-type {
        margin: 0px 3px 0px 0px;
        .header-text-with-sort {
          margin-left: 0px;
        }
      }
      &:last-of-type {
        margin: 0px 0px 0px 3px;
      }
    }
    .table-body {
      tr {
        td {
          padding: 0.5rem;
          font-size: 14px;
        }
        &:hover {
          td {
            background-color: #fafafa;
          }
        }
        &:last-of-type {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .paginate-info-row {
    select {
      width: 60px;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    .page-item.active .page-link {
      background-color: #5867dd;
      border-color: #5867dd;
    }
    .page-item:not(.disabled):not(.active) .page-link {
      color: #5867dd;
    }
  }
}
