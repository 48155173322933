@import '../Colours.scss';

.submissions-page {
  .card-content {
    min-height: 300px;
  }
  .data-message {
    flex-grow: 1;
  }
  .progress-area {
    display: flex;
    align-items: center;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  .card-title-stats {
    color: $title_text_grey;
    font-weight: 200;
    font-size: 18px;
  }

  #email-toggle.form-check-input {
    filter: hue-rotate(18deg);
  }
  .email-toggle-container {
    label {
      cursor: pointer;
      margin-right: 40px;
    }
  }
  .form-switch, .form-switch input {
    cursor: pointer;
  }

  .table-wrap {
    overflow: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  table {
    thead {
      position: sticky;
      top: 0;
      z-index: 3;
      tr:first-child th {
        white-space: inherit;
      }
    }
    tr {
      height: fit-content;
    }
    tr > th {
      white-space: unset;
      word-break: break-word;
    }
  }

  .header-text-with-sort {
    margin-left: 18px;
  }
  .table-sort-icons {
    font-size: 1.1rem;
    .sort-up {
      margin-bottom: -0.5rem;
    }
    .sort-down {
      margin-top: -0.5rem;
    }
    .sort-up, .sort-down {
      color:#dcdcdc;
      line-height: 0.5rem;
      &.sorted {
        color: #7a80dd;
      }
    }
  }

  .toggle-columns-btn {
    color: #878f97;
    border-color: #878f97;
    &:hover {
      background-color: whitesmoke;
      color: #575962;
      border-color: #575962;
    }
  }

  .paginate-info-row {
    select {
      width: 60px;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    .page-item.active .page-link {
      background-color: #5867dd;
      border-color: #5867dd;
    }
    .page-item:not(.disabled):not(.active) .page-link {
      color: #5867dd;
    }
  }
  th.selection-column {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .selection-column {
    width: 60px;
    position: sticky;
    right: 0;
    display: flex;
    input[type=checkbox] {
      filter: hue-rotate(18deg); // Change the native colour of the checkbox
      height: 18px;
      width: 18px;
      cursor: pointer;
      margin-top: 0;
    }
    .btn-dropdown-selection-menu {
      height: fit-content;
      .angle-icon {
        color: #6C6F7A;
        &:hover {
          color: #3f4047;
        }
      }
    }
    .btn-style-removed {
      all: unset;
      cursor: pointer;
      &:focus {
        outline: revert;
      }
      &:active {
        all: unset;
      }
    }
  }
  tr:hover > td.selection-column {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
  }
  td.selection-column {
    background: white;
  }
}

#submissions-table-column-toggle {
  .popover-arrow {
    display: none;
  }
  .popover-body {
    text-align: start;
    color: #575962;
    padding-bottom: 0px;
  }

  label {
    cursor: pointer;
  }
  input[type=checkbox] {
    filter: hue-rotate(18deg); // Change the native colour of the checkbox
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-top: 0;
  }
}

#submissions-table-select-action {
  .popover-arrow {
    display: none;
  }
  .popover-body {
    color: #575962;
    font-size: 1rem;

    .header-row {
      border-bottom: 1px solid #ebebeb;
      padding: 0 10px 0 10px;
      margin-right: -10px;
      margin-left: -10px;
      h3 {
        font-weight: 200;
      }
    }
  }

  .btn-style-removed {
    all: unset;
    cursor: pointer;
    &:focus {
      outline: revert;
    }
    &:active {
      all: unset;
    }
  }
}

#builder-delete-submissions-modal {
  min-width: 500px;
  color: $text_grey;
  h3 {
    font-weight: 300;
    margin-bottom: 0;
  }
  .modal-content {
    min-height: 200px;
  }
}

#builder-submissions-export-modal {
  #datepicker {
    max-width: fit-content;
    .datepicker-dates {
      margin-right: 8px;
    }
    .drp-buttons {
      display: none;
    }
  }

  .primary-btn-with-icon {
    .spinning-icon {
        color: white;
    }

    svg {
      path {
        stroke:white;
      }
    }
  }
}