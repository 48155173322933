@import '../../Colours.scss';

.form-edit {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .title-row {
    margin-bottom: 30px;
    min-height: 39px;
    #form-title {
      padding: 7px 25px 7px 0;
      margin-bottom: 0;
    }
  }

  .edit-card {
    margin-bottom: 2.2rem;
  }

  .card-body {
    padding: 20px 2.2rem 2rem 2.2rem;
  }

  .card-title-row {
    // To spread the underline across the top of the card
    margin-left: -2.2rem;
    padding-left: 2.2rem;
    margin-right: -2.2rem;
    padding-right: 2.2rem;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebedf2;

    .card-title {
      font-size: x-large;
      color: #575962;
    }

    #card-header-nav-tabs {
      margin-right: 0;
      .nav-item {
        padding-right: 30px;
        padding-left: 5px;
        margin-bottom: 0;

        border-bottom: 1px solid #ebedf2;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        .nav-link {
          border: none;
          padding: 0 0 8px 0;
          margin-bottom: -1px;
          color: $text_grey;
          font-size: 1.1rem;
          &:hover {
              color: #3f4047;
              border-bottom: 1px solid #3f4047;
          }
          &.active {
            color: #3f4047;
            border-bottom: 1px solid #3f4047;
          }
        }
      }
    }
  }

  .tab-content {
    width: 100%;
  }

  .card-content {
    padding-top: 15px;
  }

  #form-load-error {
    text-align: center;
    h3 {
      font-size: 1.2rem;
    }
  }

  .alert-danger {
    .alert-text {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .btn-outline-primary.reset {
    border-color: #2b52b7;
    color: #2b52b7;

    &:hover {
      color: white;
      border-color: #2b52b7;
      background-color: #2b52b7;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(43,82,183, 0.5);
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
      color: #2b52b7;
      border-color: #2b52b7;
      background-color: white;
    }
  }

  .form-actions {
    h4 {
      font-size: 1.5rem;
    }
    .input-feedback {
      font-size: 0.9rem;
      color: grey;
    }
  }
}

#reset-data-modal {
  max-width:700px;
  .btn-primary.reset {
    color: white;
    border-color: #2b52b7;
    background-color: #2b52b7;

    &:hover {
      border-color: #213f8c;
      background-color: #213f8c;
    }
  }
  .form-check {
    line-height: 22px;
  }
  .form-check-input:checked + .form-check-label {
    font-weight: 500;
    color: #212529;
  }

  .form-check-label {
    color: #5c5c5c;
    transition: all .2s ease-in-out;
  }
  .reset-info {
    color: #5c5c5c;
  }

  input[type=checkbox] {
    filter: hue-rotate(140deg); // Change the native colour of the checkbox
    height: 14px;
    width: 14px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    &:not(:checked) {
      opacity: 0.5; // To lighten the grey border
    }
  }
}

#copy-text-tooltip {
  .tooltip-inner {
    background-color: #5e6cff;
  }
  .tooltip-arrow::before {
    border-top-color: #5e6cff;
  }
}
