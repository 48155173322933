@import '../../Colours.scss';

#field-abandons-over-time {
  .granularity-buttons {
    color: $dark_text_grey;

    label {
      text-transform: capitalize;
    }
    p {
      margin: 0 0.75rem 0 0.5rem;
    }
  }
}

#abandon-field-select {
  width: 300px;
}

#field-times-overview {
  .cell-header-allMeanTif {
    white-space: nowrap;
  }
}

.field-data {
  // Copies of the charts to fix the height/width for PDF export
  #field-abandons-chart-copy, #total-field-returns-chart-copy {
    display: none !important;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .progress-area {
    display: flex;
    align-items: center;
    height: 351px;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
    p {
      height: 20px;
    }
  }

  .scrollable-chart-wrapper {
    max-height: 351px;
    overflow-y: scroll;
    canvas {
      display: block;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .info-alert {
    margin-top: -10px;
    .alert {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      background-color: #ebecff;
      border: 1px solid #e5e7ff;
      color: $text_grey;
      a {
        color: $text_grey;
      }
    }

    .text-more-info {
      text-decoration: underline;
      text-decoration-style: dotted;
      &:hover {
        color: #6c6f7a;
      }
    }
  }

  .flip-card {
    .card {
      background-color: transparent;
    }
    perspective: 1000px;
    background-color: transparent;
    margin-bottom: 2.2rem;

    .card-body {
      min-height: 445px;
      z-index: -1;
    }

    .card-tooltip {
      max-width: fit-content;
      .info-circle-icon {
        cursor: pointer;
        color: #a291ca;
        transition: all .2s ease-in-out;
        &:hover {
          color: #6950a5;
          transform: scale(1.1);
        }
      }
    }

    .flip-card-inner {
      transition: transform 0.6s;
      transform-style: preserve-3d;
        &.perform-flip  {
          transform: rotateX(180deg);
        }
      }

    .flip-card-front, .flip-card-back {
      min-width: 100%;
    }

    .flip-card-front {
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: white;
      padding: 20px 2rem 0rem 2rem;
      transform: rotateX(0deg);
      opacity: 1;
      z-index: 2;
      &.not-visble {
        animation: fadeOut 0.2s linear forwards;
      }
      &.visible {
        z-index: 2;
        animation: fadeIn 0.2s linear forwards;
      }
    }

    .flip-card-back {
      position: absolute;
      bottom: 0;
      background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
      padding: 2px;
      -moz-transform: rotateX(180deg);
      transform: rotateX(180deg);
      height: 100%;
      &.not-visble {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        animation: fadeOut 0.3s linear forwards;
      }
      opacity: 0;
      &.visible {
        animation: fadeIn 0.3s ease-in forwards;  // Animation (as opposed to transition) improves the flip in Chrome as we're having to set z-index
        z-index: 2; // Required for links to be clickable in Chrome
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    .card-contents {
      background-color: white;
      padding: 18px 26px 22px 26px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .text-content {
        overflow-y: auto;
          &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 4px;
        }
          &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: rgba(0, 0, 0, .5);
        }
      }
      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
        h4 {
          font-weight: 500;
          font-size: 1.3rem;
        }
      }
    }
    #Field-Abandons-Img, #Field-Returns-Img {
      height: auto;
      width: 90%;
      max-width: 500px;
    }
    #Abandoned-Fields-Img, #Field-Times-Img, #Field-Returns-Significant-Diff-Img {
      height: auto;
      width: 90%;
      max-width: 600px;
    }
  }

  .pdf-export-icon {
    margin-right: 5px;
  }
  .csv-export-icon {
    margin-right: 5px;
    &.generating {
      margin-right: 1px;
    }
  }

  .text-badge {
    padding: 4px;
    border-radius: 4px;
    font-weight: 500;
    transition: all .15s ease-in-out;
    background-color: #fdf3ce;
    color: #8b8f9c;
    border: 1px solid #FFE46F;
    &:hover {
      background-color: #ffe98e;
      color: #858893;
    }
  }

  .abandoned-colour-bar, .completed-colour-bar {
    display: inline-block;
    width: 20px;
    height: 10px;
    margin-right: 8px;
    border-radius: 0.25rem;
  }

  .abandoned-colour-bar {
    background-color: #f9b2b6;
  }

  .completed-colour-bar {
    background-color: #91c2ef;
  }

  [class$="-significant-diff-row"] {
    .abandoned-cell,  .completed-cell {
      padding: 4px 6px;
      border-radius: 0.25rem;
      &:hover {
        color: #303136;
      }
    }
  }

  .abandoned-significant-diff-row {
    .abandoned-cell {
      background-color: #f9d2d4;
    }
    &:hover {
      background-color: #f9d2d47d !important;
    }
  }

  .completed-significant-diff-row {
    .completed-cell {
      background-color: #bcdaf6;
    }
    &:hover {
      background-color: #bcdaf67d !important;
    }
  }

  #summary-field-data-table {
    .table-wrap {
      max-height: 400px;
    }
  }

  table {
    .cell-header-label {
      max-width: 600px;
    }
  }

  #datepicker {
    min-width: 30%;
  }
}

.field-data .card {
  margin-bottom: 2.2rem;
  .card-body {
    padding: 20px 2rem 0rem 2rem;
    .card-title-row {
      .col {
        min-height: 44px;
        display: flex;
        align-items: center;
      }
    }
    .card-tools {
      max-width: 100%;
    }
    .card-vis {
      padding-top: 0.5rem;
      padding-bottom: 1rem;
    }
    i.info {
      margin: 10px;
      font-size: 20px;
      padding: 2px;
    }
  }
}

#field-returns-overview, #field-times-overview {
  .header-row-fieldOrder_placeholder_0, .header-row-label_placeholder_1,
  .header-row-htmlTagName_placeholder_2, .header-row-htmlType_placeholder_3, .header-row-htmlId_placeholder_4, .header-row-htmlName_placeholder_5 {
    background-color: white;
  }
}

#field-returns-overview {
  .cell-header-allSessionsTotal, .cell-header-allSessionsReturned, .cell-header-allMeanRtf {
    border-left: 1px solid whitesmoke;
  }
  .flip-card-back {
    h4 {
      font-weight: 500;
      font-size: 1.3rem;
    }
  }
}
