.listbox-wrapper {
  &.with-scroll {
    background-color: white;
    max-height: 120px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  ul {
    background-color: white;
    list-style-type: none;
    padding: 0px;
    border: 1px solid #EBEDF2;
    border-radius: 4px;

    li {
      cursor: pointer;
      padding: 8px 12px;
      color: #3f4047;
      transition: all 100ms;
      &.selected {
        background-color: #E2E5Ec;
      }
      &:hover {
        background-color: #F4F5F8;
      }
    }
  }
}