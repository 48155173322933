.form-builder {
  .question-config {
    flex-grow: 0; // Doesn't show as flex-grow is 0
    position: relative;
    border-left: 1px solid #7d7d7d;

    .config-container {
      padding: 10px 15px;
    }

    max-width: 350px;
    h3 {
      font-weight: 300;
    }

    // Slide in from right
    &.slide-in-right-col-enter {
      opacity: 0;
    }
    &.slide-in-right-col-enter-active {
      flex-grow: 1;
      opacity: 0.7;
      transition: opacity 500ms ease-in, flex-grow 200ms ease-in;
    }
    &.slide-in-right-col-enter-done {
      flex-grow: 1;
      opacity: 1;
      transition: opacity 200ms ease-in
    }
    &.slide-in-right-col-exit {
      opacity: 1;
    }
    &.slide-in-right-col-exit-active {
      opacity: 0.8;
      flex-grow: 0;
      transition: opacity 100ms ease-out, flex-grow 100ms ease-in-out;
      // Makes the text and items stay in position whilst being faded and slide out
      overflow-x: hidden;
      white-space: nowrap;
      .row {
        flex-wrap: unset;
      }
      .editable-textarea {
        white-space: wrap;
      }
    }
  }

  .configure-form {
    .form-label {
      margin-bottom: 0.25rem;
    }

    .form-control[readonly] {
      background-color: whitesmoke;
      color: #575962;
      border-color: #ebedf2;
      &:focus {
        border-color: #ebedf2;
        box-shadow: none;
      }
    }

    input[type=checkbox], input[type=radio] {
      filter: hue-rotate(18deg); // Change the native colour of the checkbox
      height: 18px;
      width: 18px;
      cursor: pointer;
      margin-top: 0;
    }

    label {
      color: #3f4047;
    }

    .input-feedback {
      font-size: 0.9rem;
      color: grey;
    }

    .invalid-input {
      border-color: #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }

    .invalid-input-feedback {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    .delete-option {
      margin: 0 -5px 0 0.25rem;
      cursor: pointer;
      &:hover {
        color: #303136;
      }
    }
    .plus-inside-btn {
      margin-bottom: 0.2rem;
    }
    .single-entry-options {
      .invalid-input-feedback {
        margin-top: -3.5px;
      }
    }

    .other-input {
      background-color: #f7f7f7;
    }

    .toggle-buttons {
      .btn:first-of-type {
        margin-left: 0;
      }
      .btn:hover {
        color: white;
        background-color: #878f97;
      }
    }

    .bulk-options-group {
      label {
        font-size: 0.9rem;
        color: #6e707c;
      }
    }

    #bulk-options {
      height: fit-content;
      max-height: 220px; // Around 10 rows high
      min-height: 54px; // Around 2 rows high
    }
  }

  .form-group:has(label[for="hint"]) {
    .editable-textarea {
      min-height: 54px;
    }
  }
  .editable-textarea {
    border-radius: 0.25rem;
    color: #495057;
    width: 100%;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }

    &.invalid-input {
      border-color: #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }

  .icon-only-button {
    &:hover {
      color: #3f4047;
      background-color: unset;
      border-color: #3f4047;
    }
  }

  fieldset {
    legend {
      font-size: 14px;
      color: #3f4047;
    }
    label {
      font-size: 92%;
    }
    .radio-group-container {
      border: 1px solid #ced4db;
      border-radius: 0.25em;
      padding: 5px 8px;
      div:not(:last-of-type) {
        margin-bottom: 5px;
        margin-right: 10px;
      }
      div:last-of-type {
        margin-bottom: 0px;
      }
      label {
        margin-left: 0.25rem;
      }
    }
  }
}

#link-config-popover {
  min-width: 310px;
  max-width: 310px;
  .popover-arrow {
    display: none;
  }
  .popover-body {
    border: 1px solid #6d757d;
    text-align: start;
    color: #575962;
    font-size: 1rem;
    padding: 10px;
  }
  .input-feedback {
    font-size: 0.9rem;
    color: grey;
  }
  .invalid-input {
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
  .invalid-input-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}
