@import '../../Colours.scss';

.insights {
  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .card-tagline {
    font-weight: 400;
    font-size: 1.1rem;
  }

  .field-insights {
    .card-body {
      min-height: 356px;
    }
  }

  .card {
    .card-body {
      padding: 10px 1.5rem 0rem 1.5rem;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
        }
      }
      .card-vis {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }
    }
  }

  .insights-card {
    border: 1px solid #eae3f7;
  }

  .url-copy-icon {
    margin-right: 5px;
  }

  .progress-area {
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  .sample-badge {
    margin-right: -10px;
    margin-top: -15px;
    white-space: nowrap;
  }

  .text-badge {
    padding: 4px;
    border-radius: 4px;
    font-weight: 500;
    color: $text_grey;
    border: 1px solid #a1a0a2;
    box-shadow: 0 1px 2px 1px rgba(231, 229, 232, 0.4);
    &:hover {
      border-color: $text_grey;
      color: #6c6f7a;
    }
  }

  #recurring-fields-toggle.form-check-input:checked {
    background-color: #f2af7bde;
    border-color: #f2af7bde;
  }

  .form-switch {
    label {
      cursor: pointer;
    }
  }

  #datepicker {
    min-width: 30%;
  }

  #submit-field-select {
    width: 300px;
  }

  .chart-axes-title {
    font-size: 0.8rem;
    margin-top:-1.5rem;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    color: #666;
    font-size: 12px;
  }

  .recurring-fields-key {
    opacity: 0;
    &.display-key {
      animation: FadeIn 0.2s linear forwards;
    }
    font-size: 0.9rem;
    color: #3e4046;
    .key-item {
      display: inline-block;
      padding: 1px 2px;
      margin: 0px 2px;
      border-radius: 0.25rem;
    }
  }
}