.table-wrap {
  max-height: 400px;
  overflow: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
  }
}

.datatable {
  width: 100%;
  thead {
    overflow-x: hidden;
    tr:nth-child(1) th {
      white-space: nowrap;
      position: -webkit-sticky;
      position: sticky;
      top:0;
      z-index: 3;
    }
    tr:nth-child(2) th{
      white-space: nowrap;
      position: -webkit-sticky;
      position: sticky;
      top: 40px; // Fixed height to allow second header to stick in place when scrolling
    }
  }

  .replay-link-grid {
    grid-template-columns: 1fr auto 1fr;

    .grid-item {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .video-icon-wrap {
      display: flex;
      width: fit-content;
    }
  }
}

.header-row-label {
  min-width: 150px;
}

.column-toggle-btn {
  font-size: 13px;
  font-weight: 500;
  color: #575962;
  padding: 10px 14px;
  background-color: whitesmoke;
  padding-left: 14px;
  padding-right: 14px;
  margin-right: 8px;
  &:hover {
    background-color: #ebebeb;
  }
}

.datatable-search-filter {
  font-weight: 500;
  input {
    width: 100%;
    max-width: 160px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
}

.table-header-row {
  height: 40px; // Fixed height to allow second header to stick in place when scrolling
  text-align: center;
  line-height: 18px;
  color: #575962;
  --bs-table-color-state: #575962;
  th {
    border-bottom: none !important;
    border-top: none !important;
    vertical-align: middle !important;
    font-size: 13px;
    font-weight: 500;
    background-color: whitesmoke;
  }
  .table-sort-icons {
    padding-left: 4px;
    font-size: 1.1rem;
    .fa-sort {
      color:#dcdcdc;
    }
    .fa-sort-up, .fa-sort-down {
      color: #7a80dd
    }
  }
}

.table-body {
  tr {
    height: 48px;
    td {
      border-top: none;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      vertical-align: middle;
      --bs-table-striped-color: #575962;
      color: #575962;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  --bs-table-striped-bg: #f9f9f9;
  background-color: #f9f9f9;
  &:hover {
    background-color: whitesmoke;
  }
}

#datatable-column-toggle {
  .popover-arrow {
    display: none;
  }
  .popover-body {
    text-align: start;
    color: #575962;
    padding-bottom: 0px;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  input[type=checkbox] {
    filter: hue-rotate(18deg); // Change the native colour of the checkbox
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-top: 0;
    margin-right: 7px;
  }
}