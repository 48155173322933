@import '../../Colours.scss';

.choose-plan {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    padding-left: 30px;
    padding-right: 45px;
    max-width: 1500px;
  }

  .title-row {
    margin-bottom: 0;
  }

  .step-title {
    font-size: 2rem;
    padding-bottom: 7px;
    margin-bottom: 0;
    color: #575962;
  }

  .billing-title-row {
    margin-bottom: 20px;
   }

  .tag-line-row {
    margin-bottom: 20px;
  }

  input[type=checkbox] {
    margin-top: 1px;
    accent-color: #271e3f;
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-top: 0;
    border-color: #271e3f;
    &:checked {
      background-color: #271e3f;
    }
  }

  #replay-checkbox-wrapper {
    font-size: 1.2rem;

    sup {
      top: -1.2em;
    }

    .beta-tab-badge {
      padding: 0.25em 0.4em;
      background: #5867dd;
      margin-left: -20px;
      font-size: 0.7rem;
    }
  }

  .session-replay-item {
    border: 1px solid #7c88e4;
    border-radius: 0.25rem;
    padding: 2px 3px;
    color: #5162DB;
    background-color: #f0f1ff;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  #plan-length-toggles, #currency-toggles {
    .btn {
      transition: all .25s ease-in-out;
      padding: 5px 15px;
      border-color: #271E3F;
      &-check.checked {
        color: white;
        background-color: #271E3F;
      }
    }
    .btn-outline-secondary {
      --bs-btn-active-bg: #271E3F;
      &:hover {
        color: white;
        background-color: #271E3F;
      }
      &.focus {
        box-shadow: 0 0 0 0.2rem rgba(39,30,63, 0.2);
      }
    }
    label {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
    }
  }

  #currency-toggles {
    .btn {
      &.active {
        font-weight: 500;
      }
    }
  }

  .plans-middle-msg {
    font-size: 1.5rem;
  }

  .card-group {
    margin-left: 0;
    margin-right: 0;

    .session-count-limit {
      font-size: 2rem;
      font-weight: 500;
    }

    .monthly-change-amount {
      font-weight: 500;
    }

    .custom-package-text {
      font-size: 70%;
      font-weight: 500;
    }

    .features-list {
      font-size: 1rem;
      font-weight: 300;
      ul {
        padding-inline-start: 0px;
        list-style-type: none;
      }
    }

    .card {
      border-radius: .15rem;
      font-size: 1.5rem;
      color: #575962;
      width: 100%;

      .choose-plan-btn {
        transition: all .35s ease-in-out;
        margin: 0;
        padding: 8px 25px;
        border-color: #EB2F5C;
        background-color:  #EB2F5C;
        &:hover {
          background-color: #931C4B;
          border-color: #931C4B;
        }
      }
      .btn-primary {
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(235,47,92, 0.5);
        }
      }
    }

    .plan-card-col:last-of-type {
      [class^="card-border"] {
        margin-right: 0;
      }
    }
    [class^="card-border"] {
      height: 100%;
      display: flex;
      position: relative;
      border-radius: .25rem;
      padding: 3px;
      margin: 0 30px 0 0;
      transition: all .2s ease-in-out;
      &:hover {
        transform: scale(1.02);
      }
    }
    .card-border-0 {
      background: linear-gradient(to right, #F5D28D, #ffc95f);
      box-shadow: 0 5px 10px 2px rgba(255,202,97,0.3);
    }
    .card-border-1 {
      background: linear-gradient(to right, #ffc95f, #ee345d);
      box-shadow: 0 5px 10px 2px rgba(238,52,93,0.2);
    }
    .card-border-2 {
      background: linear-gradient(to right, #ee345d, #c22959);
      box-shadow: 0 5px 10px 2px rgba(194,41,89,0.2);
    }
    .card-border-3 {
      background: linear-gradient(to right, #c22959, #6950a5);
      box-shadow: 0 5px 10px 2px rgba(105,80,165,0.2);
    }
    .card-border-custom {
      background: linear-gradient(to right, #6950a5, #271E3F);
      box-shadow: 0 5px 10px 2px rgba(39,30,63, 0.2);

      .choose-plan-btn {
        margin: 0;
        padding: 8px 25px;
        border-color: #674ea1;
        background-color:  #674ea1;
        &:hover {
          background-color: #533f88;
          border-color: #533f88;
        }
      }
      .btn-primary {
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(103,78,161, 0.5);
        }
      }
    }
  }

  .plan-card-col {
    min-width: 220px;
    max-width: 300px;
    padding-top: 5px;
  }

  .lite-list {
    margin-bottom: 35px;
  }

  .smaller-text {
    font-size: 78%;
  }

  .faqs-row {
    padding-bottom: 2.2rem;
    margin-left: 10%;
    margin-right: 10%;
    .card-title {
      font-weight: 500 !important;
      margin-bottom: 10px !important;
    }
    .card-text {
      font-size: 1rem;
      color: $text_grey;
    }
    .card {
      background: rgba(226,226,228,0.5);
      margin-bottom: 20px;
    }
  }

  .border-company-details {
    background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5, #271E3F);
    box-shadow: 0 5px 10px 2px rgba(105,80,165,0.2);
    padding: 3px;
    border-radius: 0.25rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    .billing-details-card {
      #details-form {
        max-width: 575px;
        .form-title-row {
          line-height: 18px;
          color: $text_grey;
          padding-top: 15px;
          padding-bottom: 15px;
          h3 {
            font-size: 1.2rem;
            margin-bottom: 0;
          }
        }
        .input-col {
          max-width: 550px;
        }
        label {
          margin-bottom: 5px;
          color: #3f4047;
          font-size:14px;
          min-width: 200px;
        }
        .form-group {
          padding-bottom: 10px;
          margin-bottom: 0;
        }
        .form-control::placeholder {
          opacity: 0.6;
        }
        .form-control[readonly] {
          background-color: whitesmoke;
          color: #575962;
          border-color: #ebedf2;
          &:focus {
            border-color: #ebedf2;
            box-shadow: none;
          }
        }
        #country-select {
          line-height: 21px;
          font-size: 14px;
        }
        .invalid-input-feedback {
          width: 100%;
          margin-top: 0.25rem;
          font-size: 80%;
          color: #dc3545;
        }


        .selected-plan-row {
          border-bottom: 1px solid #ebedf2;
          margin-bottom: 15px;
          padding-bottom: 10px;
          font-size: 1rem;
          .start-col {
            margin: 0;
            padding-right: 10px;
            text-align: right;
          }
          .selected-plan-pill {
            color: $text_grey;
            border: 1px solid #EB2F5C;
            border-radius: 0.25rem;
            font-size: 1rem;
          }
        }

        .form-actions {
          padding-top: 15px;
          .back-btn {
            padding: 5px 10px;
          }
          .continue-btn {
            .btn-primary {
              margin: 0;
              transition: all .35s ease-in-out;
              border-color: #EB2F5C;
              background-color:  #EB2F5C;
              &:hover {
                background-color: #931C4B;
                border-color: #931C4B;
              }
            }
          }

          .continue-error-msg-col {
            p {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .transferring-msg-col {
    min-height: 510px;
    font-size: 1.3rem;
  }

  // Transitions

  .step-fade-enter {
    opacity: 0;
  }
  .step-fade-enter-active {
    opacity: 1;
    transition: opacity 200ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  .step-fade-exit {
    opacity: 1;
  }
  .step-fade-exit-active {
    opacity: 0;
    transition: opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .plan-fade-enter {
    opacity: 0;
  }
  .plan-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .plan-fade-exit {
    opacity: 1;
  }
  .plan-fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  .amount-fade-enter {
    opacity: 0;
  }
  .amount-fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  }
  .amount-fade-exit {
    opacity: 1;
  }
  .amount-fade-exit-active {
    opacity: 0;
    transition: opacity 100ms ease-out;
  }
}
