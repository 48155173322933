#login-page {
  .center-column {
    padding: clamp(15px, 10%, 50vh) 2rem 5% 2rem;
  }

  #login-container {
    margin: 0 15px;
    #login-container-col {
      width: 450px;
    }
  }

  .input-link {
    font-size: 1.1rem;
    margin-top: 0.25rem;
    a {
      color: #aba5b6;
      &:hover {
        color: #776e87;
      }
    }
  }

  .alert-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    .alert-svg-icon {
      max-width: 32px;
    }
    .alert {
      margin-bottom: 0;
    }
  }

  .alert-danger, .alert-success {
    .alert-text {
      padding: 1.45rem 0rem 1.45rem 1.25rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .password-show-icon-container {
    color: #91899f;
    background: #f7f6f9;
    border-color: #ebedf0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }

  .invalid-feedback {
    font-size: 85%;
    margin-bottom: -19px;
    display: block;
    min-height: 16.25px;
  }

  .mfa-info {
    font-size: 1.1rem;
    color: #847b93;
    line-height: 1.4rem;
  }

  .verification-code {
    font-size: 1.5rem;
    font-weight: 500;
    width: unset; // Allow the html size attr to set the width
    border-color: #b1bac4;
  }

  .login-link {
    color: #847b93;
    font-size: 1.1rem;
  }

  .signup-divider {
    margin: 10px 0;
    hr {
      width: 45%;
    }
    p {
      font-size: 1rem;
    }
  }

  .oauth-container {
    max-width: 450px;
  }
}
