@import '../../Colours.scss';

.integrations {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .title-row {
    margin-bottom: 30px;
    min-height: 39px;
    #form-title {
      padding: 7px 25px 7px 0;
      margin-bottom: 0;
    }
  }

  .card-body {
    padding: 20px 2.2rem 2rem 2.2rem;
  }

  .card-title-row {
    // To spread the underline across the top of the card
    margin-left: -2.2rem;
    padding-left: 2.2rem;
    margin-right: -2.2rem;
    padding-right: 2.2rem;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebedf2;

    .card-title {
      font-size: x-large;
      color: #575962;
    }
  }

  .card-content {
    padding-top: 15px;
  }

  #forms-table {
    thead {
      color: $text_grey;
    }

    thead > tr > th {
      vertical-align: middle;
      border-top: 0;
      font-size: 1.2rem;
      font-weight: 500;
      &.link-col {
        width: 1%;
      }
    }

    tbody > tr > td {
      color: #3f4047;
      vertical-align: middle;
      &.link-col {
        width: 1%;
        font-weight: 500;
        white-space: nowrap
      }
    }

    tbody > tr {
      &:last-of-type {
        border-bottom: 2px solid #dee2e6;
      }
    }
  }
}
