@import '../../Colours.scss';

.query-nav-wrapper {
  box-shadow: 0px 3px 4px 0px #e6e6e6;
  background-color: #f6f5fa;
  position: sticky;
  top: 0;
  z-index: 9;

  #datepicker {
    min-width: 30%;
  }

  .nav-secondary {
    .open-rules-cta-row {
      font-size: 16px;
      .badge {
        background-color: #d1d6ff;
        box-shadow: 0 1px 2px 1px rgba(169, 185, 239, 0.2);
        border-radius: 0.25rem;
        color: #6C6F7A;
        font-size: 1rem;
        white-space: normal;
        text-align: left;
      }

      .include-custom-events {
        font-size: 1.1rem;
      }

      .form-check-inline {
        margin-right: 0px;
      }
      .form-check-label {
        cursor: pointer;
      }
      .form-check-input {
        margin-right: 0;
      }
      input[type=checkbox] {
        filter: hue-rotate(18deg); // Change the native colour of the checkbox
        height: 15px;
        width: 15px;
        cursor: pointer;
        &:not(:checked) {
          opacity: 0.5; // To lighten the grey border
        }
      }

      .btn-group {
        height: fit-content;
      }

      .rules-cta-button {
        color: #5162DB;
        background-color: #f0f1ff;
        border-color: #7c88e4;
        .angle-icon {
          transition: transform .5s ease;
          &.flip {
            transform: rotate(180deg);
          }
        }
        &:active, &:focus, &:not(:disabled):not(.disabled):active {
          color: #5162DB;
          background-color: #f0f1ff;
          border-color: #7c88e4;
        }
        &.close-btn {
          border-left: 0;
        }
      }
    }

    .rules-collapse-area {
      background: #f6f5fa;
      border-radius: 0.25rem;
    }

    .text-more-info {
      text-decoration: underline;
      text-decoration-style: dotted;
      text-underline-offset: 3px;
      cursor: pointer;
    }

    .custom-events-label {
      min-width: 125px;
    }

    #metric-field-select-cell {
      width: 300px;
    }

    label.mb-19 {
      margin-bottom: 19px;
    }
    .input-feedback {
      font-size: 0.95rem;
      color: #696969;
    }

    .delete-option, .edit-option {
      cursor: pointer;
      &:hover {
        color: #303136;
      }
    }

    .add-metric-cta  {
      background-color: #6b79e1;
      border-color: #e0e3ff;
      color: white;
      &:active, &:focus, &:hover, &:not(:disabled):not(.disabled):active {
        background-color: #6b79e1;
        color: white;
      }
    }

    .min-width-1 {
      min-width: 1px;
    }

    #replay-metrics-table {
      tr {
        td:last-of-type {
          width: 1%;
          min-width: 135px;
        }
      }
      td {
        padding: 0.5rem 0.5rem;
      }

      .invalid-input-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
      }
    }

    .listbox {
      li {
        border: 1px solid white;
        border-radius: 4px;
        &.selected {
          border-color: #6b79e1;
          border-radius: 4px;
          background-color: #f5f5ff;
        }
        &:hover {
          background-color: #f5f5ff;
        }
      }
    }

    input#metric-value {
      min-height: 38px;
      &:valid {
        border: 1px solid #6b79e1;
      }
    }

    .rule-area {
      border: 1px solid #d3d9de;
      border-radius: 0.25rem;
      margin-bottom: 10px;
    }
  }

  .form-check-input { // TODO remove??
    &:not(:checked) {
      border-color: $title_text_grey;
    }
  }
}