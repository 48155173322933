@import '../variables.scss';

#zuko-builder-form {
  footer {
    margin-top: 20px;
    font-size: 0.8em;
    color: $lighter_grey;

    .forms-by-zuko {
      a {
        display: flex;
        justify-content: center;
        text-decoration: none;
        align-items: center;

        img#zuko-builder-large-logo {
          margin-left: 4px;
          width: auto;
          height: 32px;
        }

        &:visited {
          color: $lighter_grey;
        }

        &:-webkit-any-link {
          color: $lighter_grey;
        }
      }
    }
    .upgrade-prompt {
      font-style: italic;
      text-align: center;
      margin-top: 8px;
    }
  }
}
