@import '../../Colours.scss';

#fields-wizard-modal {
  color: $text_grey;
  max-width: 100%;
  margin: 2rem 4rem;
  width: 100%;

  .modal-body {
    padding-bottom: 7px;
  }

  .modal-content {
    min-height: 100%;
  }

  .modal-title {
    color: #6C6F7A;
    font-weight: 200;
    font-size: 1.75rem;
  }
  .step-intro-text {
    font-size: 1.1rem;
  }
  .multi-step-progress-bar {
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    width: 60%;
    align-self: center;
  }
  .table-responsive {
    overflow-y: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .choose-path {
    h3 {
      font-size: 1.2rem;
    }
    .option-col {
      margin: 20px 20px 10px 20px;
      padding: 10px;
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
        opacity: 0.8;
      }
    }
    .option-text {
      font-size: 1.1rem;
    }
    h5 {
      font-size: 1.3rem;
    }
  }
  .choose-tracked-field {
    &.max-modal-height-container {
      height: calc(100vh - 206px);
      overflow: auto;
    }
    .table-responsive {
      height: calc(100% - 163.5px);
    }
  }
  .label-tracked-field {
    .fade-in-row {
      opacity: 0;
      animation: FadeIn 0.2s linear forwards;
      @keyframes FadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
    .alert-row {
      min-height: 34px;
      .alert {
        margin-bottom: 0;
      }
      .alert-text {
        font-size: 1rem;
      }
    }
  }
  .order-fields {
    &.max-modal-height-container {
      height: calc(100vh - 216px);
      overflow: auto;
    }
    .table-responsive {
      margin-top: 10px;
      height: calc(100% - 99px);
    }
  }
  .hide-fields {
    &.max-modal-height-container {
      height: calc(100vh - 216px);
      overflow: auto;
    }
    .unconfigured {
      .table-responsive {
        margin-top: 10px;
        height: calc(100% - 94px);
      }
      .bottom-msg {
        bottom: 0;
      }
    }
    .configured {
      .table-responsive {
        margin-top: 10px;
        height: calc(100% - 49.5px);
      }
    }

    .nav-link {
      padding: 4px 8px;
      margin-bottom: -1px;
      color: #858893;
      font-size: 1.1rem;
      border: 1px solid #17ABA3;
      &.unconfigured-link {
        border-radius: 0.25rem 0 0 0.25rem;
      }
      &.configured-link {
        border-radius: 0 0.25rem 0.25rem 0;
      }
      &:hover {
          background-color: #eaf5f5;
      }
      &.active {
        color: white;
        background-color: #17ABA3;
      }
      &.disabled {
        opacity: 0.6;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .fade {
      transition: opacity 500ms linear;
    }
    .tab-content {
      height: calc(100% - 102px);
    }
    .hide-column {
      width: 1%;
      .form-switch {
        padding-left: 40px;
      }
      .form-check-input:checked {
        background-color: #abb4f0;
        border-color: #abb4f0;
      }
    }
    .hidden-field-row {
      transition: all .2s ease-in-out;
      opacity: 0.5;
    }
  }
  .completed-fields {
    .max-modal-height-container {
      height: calc(100vh - 216px);
      overflow: auto;
    }
    .table-responsive {
      margin-top: 10px;
      height: calc(100% - 60px);
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      background-color: #f8fbfb;
      border: 1px solid #17ABA3;
      border-radius: 0.25rem;
    }
  }
  .choose-another-cta {
    background-color: #5867dd;
    border-color: #5867dd;
  }
  .spinner-container {
    width: 169.25px;
    height: 38.75px;
    &.smaller-btn {
      width: 113.75px;
    }
  }
  #unconfigured-table, #configuring-table, #reordering-table, #hiding-unconfigured-table, #hiding-configured-table, #completed-table {
    color: $title_text_grey;
    overflow-y: auto;
    white-space: nowrap;
    thead {
      position: sticky;
      top: 0;
      font-size: 13px;
      z-index: 2;
      th {
        font-weight: 500;
      }
      background-color: whitesmoke;
      .table-sort-icons {
        cursor: pointer;
        padding-left: 4px;
        font-size: 1.1rem;
        color: #dcdcdc;
        .sorted {
          color: #7a80dd
        }
      }
      .expand-column-icon {
        cursor: pointer;
        color: #a0a3ab;
        svg {
          transform: rotate(45deg);
          &:hover {
            color: #3f4047;
          }
        }
      }
    }
    tbody {
      font-size: 12px;
      td {
        vertical-align: middle;
      }
    }
    .grip-column {
      width: fit-content;
      padding: 8px 0px 8px 5px;
      &.disabled {
        color: #a3a5ae;
      }
    }
    .field-order {
      width: 1%;
    }
    .tagname-column, .type-column {
      width: 1%;
    }
    th.name-column, th.id-column {
      width: 28%;
      min-width: 100px;
    }
    td.name-column, td.id-column {
      width: 28%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 0;

      &.expand {
        max-width: unset;
      }
    }
    .btn-col {
      width: 1%;
    }
    button {
      padding: 7px 15px;
      &.select-btn {
        background-color: #5867dd;
        border-color: #5867dd;
      }
    }
  }
  #unconfigured-table, #hiding-unconfigured-table {
    .last-tracked-column {
      padding-left: 20px;
    }
  }
  #configuring-table {
    margin-top: 10px;
    td.name-column, td.id-column {
      cursor: pointer;
    }
    td.label-column {
      padding-bottom: 5px;
      padding-top: 5px;
      min-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      input {
        width: fit-content;
        max-width: 100%;
        min-width: 100%;
      }
    }
    .btn-col {
      min-width: 120px;
    }
    .invalid-input {
      border-color: #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
    .invalid-input-feedback {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }
  }
  #reordering-table {
    td:last-child {
      width: 100%;
    }
  }
  #hiding-configured-table {
    td:nth-last-child(2) {
      width: 100%;
    }
  }
  #completed-table {
    td.label-column {
      min-width: 150px;
    }
    thead {
      background-color: #f8fbfb;
      font-size: 1.1rem;
    }
    tbody {
      font-size: 1rem;
    }
  }
  .toggle-columns-btn {
    color: #878f97;
    border-color: #878f97;
    &:hover {
      background-color: whitesmoke;
      color: #575962;
      border-color: #575962;
    }
  }
  .table-search-row {
    margin-bottom: 10px;
    input {
      width: 100%;
      max-width: 160px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
    }
    button {
      &:disabled {
        background-color: #00c5dc;
        border-color: #00c5dc;
      }
    }
  }
  .pagination {
    margin-bottom: 0;
    margin-top: 5px;
  }
  .page-item.active .page-link {
    background-color: #5867dd;
    border-color: #5867dd;
  }
  .page-item:not(.disabled):not(.active) .page-link {
    color: #5867dd;
  }

  .interactive-mode {
    &.max-modal-height-container {
      height: calc(100vh - 212px);
      overflow: auto;
    }
    .form-link-container {
      background: whitesmoke;
      border-radius: 3px;
      .loading-btn-replace {
        width: 248px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .further-info-title {
      font-weight: 500;
      margin: 20px 0 10px 0;
      font-size: 1.2rem;
    }

    .labelling-tool-tips {
      .li-main-point {
        font-weight: 500;
      }
    }
  }

  table {
    thead {
      th {
        border-bottom: none;
        padding: 0.75rem;
      }
    }
    tbody {
      tr:nth-of-type(odd) {
        --bs-table-striped-color: #575962;
      }
      td {
        border-top: none;
      }
    }
  }
}
