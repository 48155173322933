@import '../Colours.scss';

#live-page {
  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .card {
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 0rem 2rem;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
        }
      }
      .card-vis {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }
    }
  }

  .progress-area {
    display: flex;
    align-items: center;
    height: 351px;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  .refreshCountdown {
    line-height: 15px;
  }

  svg.refreshCountdownIndicator {
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    transform: rotateY(-180deg) rotateZ(-90deg);

    circle {
      stroke-dasharray: 28.25px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 1.5px;
      stroke: $title_text_grey;
      fill: none;
      animation: countdown 10s linear infinite forwards;
    }
  }

  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }
    to {
      stroke-dashoffset: 28.25px;
    }
  }

  .refreshMessage {
    font-size: 13px;
    margin: 0px 15px 0px 5px;
  }

  .flip-card {
    .card {
      background-color: transparent;
    }
    perspective: 1000px;
    background-color: transparent;
    margin-bottom: 2.2rem;

    .card-body {
      min-height: 491px;
      z-index: -1;
    }

    .card-tooltip {
      max-width: fit-content;
      .info-circle-icon {
        cursor: pointer;
        color: #a291ca;
        transition: all .2s ease-in-out;
        &:hover {
          color: #6950a5;
          transform: scale(1.1);
        }
      }
    }

    .flip-card-inner {
      transition: transform 0.6s;
      transform-style: preserve-3d;
        &.perform-flip  {
          transform: rotateX(180deg);
        }
      }

    .flip-card-front, .flip-card-back {
      min-width: 100%;
    }

    .flip-card-front {
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: white;
      padding: 20px 2rem 0rem 2rem;
      transform: rotateX(0deg);
      opacity: 1;
      z-index: 2;
      &.not-visble {
        animation: fadeOut 0.2s linear forwards;
      }
      &.visible {
        z-index: 2;
        animation: fadeIn 0.2s linear forwards;
      }
    }

    .flip-card-back {
      position: absolute;
      bottom: 0;
      background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
      padding: 2px;
      -moz-transform: rotateX(180deg);
      transform: rotateX(180deg);
      height: 100%;
      &.not-visble {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        animation: fadeOut 0.3s linear forwards;
      }
      opacity: 0;
      &.visible {
        animation: fadeIn 0.3s ease-in forwards; // Animation (as opposed to transition) improves the flip in Chrome as we're having to set z-index
        z-index: 2; // Required for links to be clickable in Chrome
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    .card-contents {
      background-color: white;
      padding: 18px 26px 22px 26px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .text-content {
        overflow-y: auto;
      }
      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
        h4 {
          font-weight: 500;
          font-size: 1.3rem;
        }
      }
    }
  }
}
