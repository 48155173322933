#request-password-reset-page {
  .center-column {
    padding: clamp(15px, 10%, 50vh) 2rem 5% 2rem;
  }
  #login-container {
    margin: 0 15px 4rem 15px;
    #login-container-col {
      width: 450px;
    }
  }

  .login-link {
    color: #aba5b6;
    font-size: 1.1rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }
}
