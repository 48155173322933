@import '../Colours.scss';

#form-details-form {
  max-width: 600px;

  input {
    max-width: 600px;
  }

  .input-feedback {
    font-size: 0.9rem;
    color: grey;
  }

  .text-more-info {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: #c9c9c9;
    &:hover {
      color: #6c6f7a;
      text-decoration-color: #6c6f7a;
    }
  }

  label {
    color: #3f4047;
    margin-bottom: 5px;
  }
  input[type=checkbox] {
    filter: hue-rotate(18deg); // Change the native colour of the checkbox
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-top: 0;
  }

  .form-title-row {
    line-height: 18px;
    color: $text_grey;
    padding-top: 15px;
    padding-bottom: 15px;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 0;
    }
  }
  .form-group {
    padding-bottom: 15px;
    margin-bottom: 0;
  }
  .form-control::placeholder {
    opacity: 0.6;
  }

  .form-actions-row {
    padding-top: 15px;
  }

  .form-control[readonly] {
    background-color: whitesmoke;
    color: #575962;
    border-color: #ebedf2;
    &:focus {
      border-color: #ebedf2;
      box-shadow: none;
    }
  }

  .invalid-input {
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  .invalid-input-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}
