@import '../../Colours.scss';

.field-flow {
  // Copies of the charts to fix the height/width for PDF export
  #field-flow-chart-copy {
    display: none !important;
  }

  .pdf-export-icon {
    margin-right: 7px;
  }
  .url-copy-icon {
    margin-right: 5px;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .nav-secondary {
    .form-check-inline {
      margin-right: 0px;
    }
    .form-check-label {
      padding-left: 0.3rem;
      cursor: pointer;
    }
    .form-check-input {
      margin-right: 0;
    }
    input[type=checkbox] {
      filter: hue-rotate(18deg); // Change the native colour of the checkbox
      height: 15px;
      width: 15px;
      cursor: pointer;
      &:not(:checked) {
        opacity: 0.5; // To lighten the grey border
      }
    }
  }

  .custom-events-alert {
    .alert {
      width: fit-content;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      background-color: #ebecff;
      border: 1px solid #e5e7ff;
      color: $text_grey;
      a {
        color: $text_grey;
      }
    }
  }

  .progress-area {
    display: flex;
    align-items: center;
    height: 351px;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
    p {
      height: 20px;
    }
  }

  .card {
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 0rem 2rem;
      .card-title-row {
        height: 44px;
      }
      .card-tools {
        max-width: 100%;
      }
      .card-vis {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }
      i.info {
        margin: 10px;
        font-size: 20px;
        padding: 2px;
      }
    }
  }

  #field-flow-vis {
    #field-flow-select {
      max-width: 400px;
    }

    .flow-chart-header-row {
      color: #575962;
    }

    .text-badge {
      padding: 4px;
      border-radius: 4px;
      font-weight: 500;
      color: $text_grey;
      border: 1px solid #a1a0a2;
      box-shadow: 0 1px 2px 1px rgba(231, 229, 232, 0.4);
      &:hover {
        border-color: $text_grey;
        color: #6c6f7a;
      }
    }

    [class$='-session-cell'] {
      padding: 5px;
      border-radius: 4px;
    }
    .Completed-session-cell {
      background-color: #ccecd1;
    }
    .Abandoned-session-cell {
      background-color: #f3cfd1;
    }
    .custom-event-session-cell {
      background-color: #ebecff;
    }

    .session-state-row {
      td:empty::before {
        content: '-';
      }
    }

    .sample-msg {
      font-weight: 500;
    }

    .intro-info {
      list-style-type: none;
      cursor: unset;
      .example-link {
        cursor: pointer;
        color: #5867dd;
        &:hover {
          text-decoration: underline;
        }
        &.example-open {
          text-decoration: underline;
        }
      }
      &:focus {
        outline: none;
      }
    }

    .table-wrap {
      max-height: 500px;
    }
  }

  .flip-card {
    .card {
      background-color: transparent;
    }
    perspective: 1000px;
    background-color: transparent;
    margin-bottom: 2.2rem;

    .card-body {
      min-height: 491px;
      z-index: -1;
    }

    .card-tooltip {
      max-width: fit-content;
      .info-circle-icon {
        cursor: pointer;
        color: #a291ca;
        transition: all .2s ease-in-out;
        &:hover {
          color: #6950a5;
          transform: scale(1.1);
        }
      }
    }

    .flip-card-inner {
      transition: transform 0.6s;
      transform-style: preserve-3d;
        &.perform-flip  {
          transform: rotateX(180deg);
        }
      }

    .flip-card-front, .flip-card-back {
      min-width: 100%;
    }

    .flip-card-front {
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: white;
      padding: 20px 2rem 0rem 2rem;
      transform: rotateX(0deg);
      opacity: 1;
      z-index: 2;
      &.not-visble {
        animation: fadeOut 0.2s linear forwards;
      }
      &.visible {
        z-index: 2;
        animation: fadeIn 0.2s linear forwards;
      }
    }

    .flip-card-back {
      position: absolute;
      bottom: 0;
      background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
      padding: 2px;
      -moz-transform: rotateX(180deg);
      transform: rotateX(180deg);
      height: 100%;
      &.not-visble {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        animation: fadeOut 0.3s linear forwards;
      }
      opacity: 0;
      &.visible {
        animation: fadeIn 0.3s ease-in forwards;  // Animation (as opposed to transition) improves the flip in Chrome as we're having to set z-index
        z-index: 2; // Required for links to be clickable in Chrome
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    .card-tagline {
      font-weight: 400;
      font-size: 1.1rem;
    }

    .text-more-info {
      text-decoration: underline;
      text-decoration-style: dotted;
      &:hover {
        color: #6c6f7a;
      }
    }

    .card-contents {
      background-color: white;
      padding: 18px 26px 22px 26px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      h4 {
        font-weight: 500;
        font-size: 1.3rem;
      }
      .text-content {
        overflow-y: auto;
      }
      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
      }
    }

    #Field-Flow-Chart, #Field-Flow-Table {
      height: auto;
      width: 75%;
      max-width: 600px;
    }
    #Field-Flow-Sample {
      height: auto;
      width: 90%;
      max-width: 600px;
    }
  }

  #datepicker {
    min-width: 30%;
  }
}
