.quick-tip-btn {
  padding: 3px 8px;
  border: 1px solid #d6557e;
  font-size: 1rem;
  color: #d6557e;
  font-weight: 500;
  background-color: white;
  transition: all .2s ease-in-out;
  border-radius: 60px;
  box-shadow: 0 4px 8px 0px rgba(235,47,92,0.19);
  font-size: 1rem;
  &:hover, &:active, &:focus, &.btn-primary:not(:disabled):not(.disabled):active {
    color:#EB2F5C;
    background-color: white;
    border-color: #EB2F5C;

    .chart-tip-icon {
      transform: scale(1.1);
      color:#EB2F5C;
    }
  }
  &:disabled, &.disabled {
    color:#EB2F5C;
    background-color: white;
    border-color: #EB2F5C;
  }
  span {
    margin-bottom: 1px;
  }

  .chart-tip-icon {
    &.data-loaded {
      animation: flashing 1s ease-in-out 3000ms 10; // Wait 3 seconds for chart to load before flashing
    }
    transition: all .2s ease-in-out;
    color: #d6557e;
    @keyframes flashing {
      50% {
        opacity: 0.4;
      }
    }
  }
}
.chart-tip-tooltip {
  background: unset;
  box-shadow: none;
  margin-right: 10px;
  z-index: 9; // to go underneath the query nav
  min-width: 400px;

  .popover-arrow {
    --bs-popover-bg: #EB2F5C;
  }
  .popover-body {
    border: 2px solid #EB2F5C;
    border-radius: 1rem;
    text-align: start;
    color: #575962;
    font-size: 1rem;
    padding: 20px 16px;
    background-color: white;

    .popover-content {
      margin-top: -5px;
    }

    .loading-area {
      min-height: 100px;
    }

    p {
      margin-bottom: 0.5rem;
    }

    .tip-field {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .tip-stat {
      font-size: 16px;
      font-weight: 500;
    }

    h4 {
      font-size: 16px;
      font-weight: 300;
    }

    i {
      font-weight: 500;
    }


    .quick-tip-internal-btn {
      color: white;
      background-color: #ed456c;
      border-color: #ed456c;
      &:hover, &:active, &:focus, &.btn-primary:not(:disabled):not(.disabled):active {
        color: white;
        background-color: #EB2F5C;
        border-color: #EB2F5C;
      }
    }
  }
}