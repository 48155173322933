.error-page {
  background-image: url(../zuko-background.jpg);
  min-height: 100vh;
  background-repeat:no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .zuko-full-colour-logo {
    background-image: url(../Zuko_Full_Colour_Logo.png);
    height: 93px;
    width: 344px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .error-message {
    margin-top: 50px;
    text-align: center;
  }
}
