// Override default variables before the import

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import 'Colours.scss';

html, body, #root {
  min-height: 100vh;
}

body {
  overscroll-behavior: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;

  .container-fluid {
    padding: 0
  }

  background-color: $light_grey;
}

html, h5, h4 {
  font-weight: 400;
}
html, h5 {
  font-size: 14px;
}

h1 {
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

h4 {
  font-size: 14px;
}

// Bootstrap 4 -> 5 overrides
.form-group {
  margin-bottom: 1rem;
}
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 0.25rem;
  color: #495057;
  &:focus {
    color: #495057;
  }
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}
.row {
  margin: 0;
}
.btn {
  width: fit-content;
  border-radius: 0.25rem;
}
.card {
  border-radius: 0.25rem;
}
.table {
  --bs-table-bg: unset;
  --bs-table-color: unset;
  th {
    border-bottom: 2px solid #dee2e6;
  }
  td {
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-width: 0;
  }
}
#card-header-nav-tabs {
  border-bottom: 0;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.col {
  position: relative;
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon); // Removes the blue svg
}

.btn-primary {
  background-color: #00B3C7;
  border-color: #00B3C7;

  &:focus, &:hover {
    background-color: #0392a2;
    border-color: #0392a2;
  }

  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    background-color: #0392a2;
    border-color: #0392a2;
  }

  &.disabled, &:disabled {
    background-color: #0392a2;
    border-color: #0392a2;
  }
}

.btn-outline-primary {
  border-color: #00c5dc;
  color: #00c5dc;

  &:hover {
    color: #00a7ba;
    border-color: #00a7ba;
    background-color: #f2fbfd;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,167,186, 0.5);
  }
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
    color: #0392a2;
    border-color: #0392a2;
    background-color: white;
  }
  &:disabled  {
    border-color: #9e9e9e;
    color: #9e9e9e;
  }
}

.btn-outline-danger {
  border-color: #db21336e;
  color: #dc3848bf;
}

.page {
  color: $text_grey;
}

.content {
  background: white;
  margin-top: 40px;
  box-shadow: rgba(150, 150, 150, 0.09) 0px 1px 8px 1px;
  padding: 30px 40px;
}

.g-0 > .col, .g-0 > [class*="col-"] {
  padding: 10px;
}

a, .clickable-text {
  color: #5867dd;
  &:hover {
    color: #2739c1;
  }
}
.clickable-text {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

.nav-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  &.introjs-fixParent {
    // Whilst Intro JS is in play, we still want scrolling to put the charts behind the fixed nav
    z-index: 10 !important;
  }
  background-color: #f6f5fa;
  box-shadow: 0px 3px 4px 0px #e6e6e6;
}

.card {
  text-align: left;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 1px 8px 1px rgba(150,150,150,0.09);
  border: none;
  .card-body {
    --bs-card-color: #575962;
    .card-title-row {
      display: flex;
      align-items: center;
    }
    .card-title {
      font-weight: 200;
      margin-bottom: 0;
      color: $title_text_grey;
    }
  }
}

.btn {
  line-height: 1.25;
  transition: all .15s ease-in-out;
  box-shadow: none;
  &:first-of-type { margin-left: 10px; }
  &:last-of-type { margin-right: 10px; }
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 22px;
  padding-left: 22px;
  font-size: 15px;
  &.cancel {
    border-color: #d1d4d7;
    color: #8c949b;
    background-color: #ffffff6b;
  }

  &.cancel:hover {
    background-color: #a2a8ae;
    border-color: #a2a8ae;
    color: #fff;
  }

  &.cancel:disabled, &.load:disabled {
    background-color: inherit;
    border-color: #fff;
    color: #6c757d;
  }

  &.save:disabled  {
    background-color: #f9f9f9;
    border-color: #fff;
    color: #9e9e9e;
  }

  &.delete {
    border-color: #db21336e;
    color: #dc3848bf;
  }
}

.alert {
  margin-bottom: 5px;
  padding-top: .3rem;
  padding-bottom: .3rem;
  display: inline-flex;
  align-items: center;
  width: 100%;
  &.alert-warning {
    background-color: #FFE46F;
    border-color: #FFE46F;
    color: $dark_text_grey;
    .alert-text {
      font-size: 12px;
    }
    a {
      color: #533f03;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
    .btn-close {
      font-size: 80%;
      color: $dark_text_grey;
      padding: 1rem 1.25rem 0rem 0rem;
    }
  }
  &.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
    .spinning-icon {
      color: #0c5460;
    }
    a, .clickable-text {
      color: #0c5460;
      text-decoration: underline;
      text-underline-offset: 3px;
      &:hover {
        color: #093b43;
      }
    }
    .btn-close {
      font-size: 80%;
      padding: 1rem 1.25rem 0rem 0rem;
    }
  }
  &.alert-danger, &.alert-success, &.alert-outline-success, &.alert-outline-danger {
    .alert-text {
      padding: 18px 15px;
      font-size: 1.2rem;
    }
    .btn-close {
      top: auto;
      padding: 18px 15px;
    }
  }
  &.alert-danger, &.alert-success {
    color: white;
    a {
      color: white;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
    .btn-close {
      color: white;
    }
    i.fa {
      font-size: 33px;
      padding: 15px 5px 15px 1px;
    }
  }
  &.alert-outline-success {
    background-color: #edf5f5;
    border-color: #17ABA3;
  }
  &.alert-outline-danger {
    background-color: #feecef;
    border-color: #F55671;
  }
  &.alert-outline-info {
    background-color: #f3fafb;
    border-color: #bee5eb;
    color: #2e8794;
  }
  &.alert-danger {
    background-color: #F55671;
    border-color: #F55671;
  }
  &.alert-success {
    background-color: #17ABA3;
    border-color: #17ABA3;
  }
  i.fa {
    font-size: 16px;
    padding-right:.5rem;
    align-self: center;
  }
}

.w-fit-content {
  width: fit-content;
}

.title-row {
  margin-bottom: 30px;
  min-height: 39px;
  i.settings, svg.settings, svg.export-icon {
    cursor: pointer;
    font-size: 20px;
    vertical-align: middle;
  }
  #form-title, #comparison-title,  #alerts-title, #usage-title {
    padding: 7px 25px 7px 0;
    margin-bottom: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    color: $title_text_grey;
  }
}

i.settings, i.info, i.editing, i.action, svg.editing, svg.settings, svg.export-icon, svg.info, svg.grey-icon {
  cursor: pointer;
  color: $text_grey;
  transition: all .2s ease-in-out;
  &.page-link-icon-disabled {
    color: #aeb0b7;
  }
  &:hover {
    color: #3f4047;
  }

  &.transform-rotate-90 {
    transform: rotate(90deg);
  }
}

.csv-export-icon, .pdf-export-icon, .png-download-icon {
  &.data-loading {
    path {
      stroke: #cccdd1;
    }
  }
  &.generating {
    path {
      stroke: transparent;
    }
  }
  &.data-loaded {
    cursor: pointer;
    path {
      stroke: $text_grey;
    }
    &:hover {
      path {
        stroke: #3f4047;
      }
    }
  }
}

.url-copy-icon {
  cursor: pointer;
}

.spinning-icon {
  color: $text_grey;
  vertical-align: middle;
  animation: spin infinite 2s linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-overlay-container {
  position: relative;

  .spinning-icon {
    margin-right: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.login-background {
  background-image: url(./zuko-background.jpg);
  background-size: cover;
  background-repeat:no-repeat;
  min-height: 100vh;

  .logo-wrapper {
    width: 20%;
    min-width: 170px;
    max-width: 200px;
  }

  .zuko-full-colour-logo {
    background-image: url(./Zuko_Full_Colour_Logo.png);
    aspect-ratio: 344 / 93;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .zuko-builder-full-colour-logo {
    background-image: url(./Zuko_Builder_Full_Colour_Logo.png);
    aspect-ratio: 344 / 93;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 63px;
    width: 200px;
  }

  .center-column {
    margin: 0 auto;
  }

  .login-title {
    margin-top: 30px;
    text-align: center;
    color: #6b6379;
    h1 {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: .5rem;
      font-weight: 500;
      line-height: 1.2;
      color: #6b6379;
    }
  }
}

#login-container {
  background-color: rgba(255, 255, 255, 0.4); // Avoid backgound dots clashing with the input label
  font-size: clamp(1rem, 3vw, 1.1rem);

  .login-form {
    .form-group {
      label {
        color: #6e717c;
      }
    }

    line-height: 16.25px;
    .form-control {
      border-color: #ebedf0;
      padding: 1.5rem 1.5rem;
      color: #91899f;
      background: #f7f6f9;
      &:focus {
        color: #776e87;
      }
      &::placeholder {
        opacity: 0.6;
      }
    }

    .invalid-input {
      border: 1px solid #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }

  .submit-btn {
    background-color: #D5396D;
    border-color: #D5396D;
    color: #fff;
    padding: 1rem 3rem;
    margin-top: .8rem;
    font-size: 1.2rem;
    font-weight: 500;

    &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active {
      background-color: #931C4B;
      border-color: #931C4B;
    }
    &:focus, &:active, &:active:focus {
      box-shadow: 0 0 0 0.2rem rgba(235,47,92, 0.5);
    }
  }
}

.page-alert-svg-icon {
  max-width: 25px;
  padding-right:.5rem;
}

.alert-svg-icon {
  max-width: 32px;
}

.popover {
  max-width: 400px;
  box-shadow: 0px 2px 7px 0px lightgray;
  text-align: center;
  border: none;
  .popover-body {
    color: $dark_text_grey;
    padding: 0.5rem 0.75rem;
  }
}

.sign-up-btn {
  padding: 5px 10px 6px 10px;
  transition: all .35s ease-in-out;
  font-size: 1.1rem;
  a {
    color: white;
    text-decoration: none;
  }
  border-color: #EB2F5C;
  background-color:  #EB2F5C;
  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active {
    background-color: #931C4B;
    border-color: #931C4B;
  }
  &:focus, &:active, &:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(235,47,92, 0.5);
  }
  &:disabled {
    border-color: #EB2F5C;
    background-color:  #EB2F5C;
  }
}

.error-text {
  color: #dc3545;
  font-size: 80%;
}

.beta-alert {
  background: #d9dcf7;
  color:#545353;
  border: 2px solid #5867dd;
  a {
    color:#545353;
  }
}

.card-title, .modal-header {
  sup {
    top: -1.2em;
  }

  .beta-tab-badge {
    padding: 0.25em 0.4em;
    background: #5867dd;
    margin-left: -10px;
    font-size: 0.7rem;
  }
}

#copy-url-icon-tooltip {
  .tooltip-inner {
    background-color: #5e6cff;
    max-width: 150px;
  }
  .tooltip-arrow::before {
    border-top-color: #5e6cff;
  }
}

#filters-select, #create-segment-filters-select,
  [id^="edit-segment-filters"], [id^="segment-filters-select-"] {
  .form-check {
    cursor: pointer;
  }
  .form-check-input:checked {
    filter: hue-rotate(18deg);
    height: 15px;
    width: 15px;
    transition: all .2s ease;
  }
  .form-check-input {
    &:not(:checked) {
      border-color: $title_text_grey;
    }
  }
  .cancel-filters {
    font-size: inherit;
    border-color: #d9dbde;
    color: #a2a8ae;
    background-color: #ffffff6b;
    transition: all .2s ease;
    &:hover {
      background-color: #FFBDAD;
      color: #DE350B;
      border-color: #DE350B;
    }
  }
}

#feedback-container, #feature-request-container, #review-container {
  min-width: 400px;
  &.allow-scroll-under-nav {
    z-index: 9; // To scroll underneath nav which is 10
  }

  .popover-arrow {
    display: none;
  }
  .popover-body {
    text-align: start;
    color: #575962;
    font-size: 1rem;
    padding: 10px;

    .header-row {
      border-bottom: 1px solid #ebebeb;
      padding: 0 10px 0 10px;
      margin-right: -10px;
      margin-left: -10px;
      h3 {
        font-weight: 200;
      }
    }

    #feedback-form {
      .invalid {
        textarea {
          border: 1px solid #dc3545;
          &:focus {
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          }
        }
      }

      textarea {
        height: 100px;
        &::placeholder {
          opacity: 0.6;
        }
        &:focus::placeholder {
          opacity: 0.6;
        }
      }

      .invalid-feedback, .valid-feedback {
        margin-top: 0.4rem;
        font-size: 85%;
        display: block;
      }

      .feedback-info {
        color: #6b6379;
      }
    }

    .button-row {
      border-top: 1px solid #ebebeb;
      margin-top: 10px;
      padding: 10px 10px 0 10px;
      margin-right: -10px;
      margin-left: -10px;
    }

    .btn-less-padding {
      padding: 7px 15px;
    }

    .loading-area {
      min-height: 100px;
    }

    .alert {
      margin-top: 10px;
    }

    .alert-outline-success, .alert-outline-danger {
      .alert-text {
        padding: 4px 8px;
        font-size: 1rem;
      }
    }
  }
}

.zuko-app-form {
  max-width: 600px;

  input {
    max-width: 600px;
  }

  .form-group {
    padding-bottom: 15px;
    margin-bottom: 0;
  }

  .form-label {
    color: #3f4047;
    margin-bottom: 5px;
  }

  .input-feedback {
    font-size: 0.9rem;
    color: grey;
  }

  .form-control[readonly] {
    background-color: whitesmoke;
    color: #575962;
    border-color: #ebedf2;
    &:focus {
      border-color: #ebedf2;
      box-shadow: none;
    }
  }
}

.details-grid {
  grid-column-gap: 60px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(300px, 35%)), 1fr));
}

.form-readonly-info {
  border: 1px solid #ced4da;
  height: fit-content;
  background: #f5f5f56e;
  border-radius: 0.25rem;
  padding: 10px;

  .text-copy-container {
    color: #91899f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #ebedf0;
    background-color: whitesmoke;
    border-left: 0;
  }
}

.am-only-input-tag {
  border: 1px solid #f7e190;
  color: #646573;
  border-radius: 0.25rem;
  padding: 1px 3px;
  font-size: 85%;
}

.fw-500 {
  font-weight: 500;
}

.attributes-icons {
  .badge {
    --bs-badge-font-size: 12px;
    margin-bottom: 2px;
    &.bg-light {
      background-color: #f2f1f4;
    }
  }
}
.blurred-report {
  filter: blur(15px);
  pointer-events: none;
}
