@import '../Colours.scss';

#alerts-page {
  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .page-tagline {
    margin-top: -20px;
  }

  .filter-badge {
    color:#575962;
    background-color:#ebedf2;
    padding: 5px;
    border-radius: 3px;
    margin: 3px;
  }

  .card {
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 2rem 2rem;
      .card-title-row {
        height: 44px;
      }
    }
    .card-vis {
      padding-top: 0.5rem;
    }
    .card-bottom-tools {
      padding: 0px 0px 30px 0px;
    }
  }

  .pill-btn {
    color: #34bfa3;
    border-color: #34bfa3;
    border-radius: 60px;
    background-color: transparent;
    padding: .65rem 1.25rem;
    margin: 0;
    &:hover {
      color: #fff;
      background-color: #34bfa3;
      border-color: #34bfa3;
    }
    &:focus {
      box-shadow: none;
    }
  }

  #event-type-select {
    color: $title_text_grey;
  }

  #datepicker {
    max-width: 500px;
  }

  #filters-select {
    width: 100%;
  }

  .progress-area {
    display: flex;
    align-items: center;
    height: 351px;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  .chartThresholds {
    padding: 5px;
    border: .07rem solid #ebedf2;
    width: 75%;
    display: flex;
    margin: 0 auto;
    color: #3f4047;

    .thresholdMetric {
      font-weight: 400;
    }
    .thresholdFigure {
      font-weight: 500;
      font-size: 17px;
    }
  }

  .success-tick-icon {
    color: #64ba7f;
  }

  .error-message {
    color: #e01f46;
  }

  .card-table {
    padding-top: 20px;

    table {
      thead {
        color: #a6a7c1;
      }

      thead > tr > th {
        border-bottom: none;
        padding-top: 1.21rem;
        padding-bottom: 1.21rem;
        padding-left: 0;
        padding-right: 1.25rem;
        vertical-align: top;
        border-top: 0;
        font-size: 1rem;
        font-weight: 600;
        &:last-of-type {
          text-align: end;
        }
      }

      tbody > tr {
        border-bottom: .07rem dashed #ebedf2;
        cursor: pointer;
      }

      tbody > tr > td {
        color: #5a5a5a;
        border: 0;
        padding-left: 0;
        padding-right: 1.25rem;
        padding-top: 22px;
        vertical-align: top;
        font-size: 1.1rem;
        font-weight: 500;
        &.disabled-alert {
          color: #969696;
        }
        &:last-of-type {
          text-align: end;
        }
      }

      tbody tr:hover {
        background-color: #f9fafb;
      }
    }

    td:not(.alert-disabled) > #alert-enabled-status {
      color: green;
    }

    .alert-disabled {
      color: #969696;
    }

    .w-1 {
      width: 1%;
    }
  }

  .page-info-card {
    width: unset;
    margin: 10px 2rem 10px 2rem;
    right: 0;
    box-shadow: 0 0 15px 1px rgba(113,106,202,.2);
    position: absolute;
    background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
    padding: 2px;
    .card-contents {
      background-color: white;
      max-height: 550px;
      padding: 20px 2rem 20px 2rem;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
          .card-title {
            margin-bottom: 0;
            font-weight: 200;
            color: #575962;
          }
        }
      }
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .text-content {
        overflow-y: auto;
      }

      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
        h4 {
          font-weight: 500;
          font-size: 1.5rem;
        }
      }
    }

    &.open {
      z-index: 4;
      animation: AlertsFadeIn 0.3s linear forwards;
    }
    &.closed {
      z-index: -1;
      animation: AlertsFadeOut 0.2s ease-out forwards;
    }
    @keyframes AlertsFadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes AlertsFadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    #Alerts-chart-Img, #Alerts-config-Img {
      height: auto;
      width: 80%;
      max-width: 500px;
    }
  }

  .original-content {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &.background {
      opacity: 0.3;
    }
  }

  .card-tooltip, .title-tooltips {
    max-width: fit-content;
    .info-circle-icon {
      cursor: pointer;
      color: #a291ca;
      transition: all .2s ease-in-out;
      &:hover {
        color: #6950a5;
        transform: scale(1.1);
      }
    }
  }
}

.alert-settings {
  color: $title_text_grey;
  padding-top: 10px;
  border-top: .09rem dashed #ebedf2;
  margin-top: 10px;

  .disabled-alert-frequency, .disabled-alert-status {
    opacity: 0.6;
  }

  #alert-between-time {
    color: #3f4047;
    .react-time-picker--disabled {
      background-color: #f6f5fa;
      opacity: 0.7;
    }
    .react-time-picker__wrapper {
      border: 1px solid #ced4da;
      border-radius: .25rem;
    }
    .react-time-picker__inputGroup {
      padding: 4px;
      min-width: 40px;
    }
    .react-time-picker__inputGroup__input {
      height: 18px;
    }
  }

  #thresholdInput, #emailInput {
    display: inline-block;
    width: auto;
    margin: 0px 10px 0px 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .input-feedback {
    font-size: 0.9rem;
    color: grey;
  }

  #thresholdInput {
    width: 100px;
  }
  #emailInput {
    width: 500px;
    max-width: 100%;
  }

  label {
    color: #3f4047;
    margin-bottom: 5px;
  }
  .form-check-label {
    margin-left: 5px;
  }
  input[type=checkbox], input[type=radio] {
    filter: hue-rotate(18deg); // Change the native colour of the checkbox
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-top: 0;
  }

  .error {
    font-size: 12px;
    color: #e01f46;
    padding-left: 5px;
  }

  .invalid-input {
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

.alert-actions {
  padding-top: 30px;

  h4 {
    font-size: 1.5rem;
  }
}
