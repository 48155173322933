$text_black: #303136;
$text_grey: #242428;
$light_grey: #54575f;
$lighter_grey: #6c6f7a;
$dark_text_black: #26272b;
$default_accent_color: #3c2e60;
$error_red: #DC3545;

$input-box-shadow: 0 0px 2px 0px rgba(108, 111, 122, 0.3), inset 0 0px 2px rgba(108, 111, 122, 0.6);

$border-radius: 5px;

$primary_button_hover_background: linear-gradient(to top, #135349, #196C60 15%);

:export {
  defaultAccentColor: $default_accent_color;
  primaryButtonHoverBackground: $primary_button_hover_background;
  textGrey: $text_grey;
  textBlack: $text_black;
  lightGrey: $light_grey;
  lighterGrey: $lighter_grey;
  inputBoxShadow: $input-box-shadow;
}
