@import '../Colours.scss';

.form-builder {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 850px;
  .nav-wrapper {
    box-shadow: none;
  }

  .secondary-nav-wrapper {
    border-bottom: 1px solid #7d7d7d;
    box-shadow: 0px 3px 4px 0px #e6e6e6;
    position: sticky;
    top: 0;
    z-index: 10;

    .btn-style-removed {
      all: unset;
      cursor: pointer;
      &:focus {
        outline: revert;
      }
      &:active {
        all: unset;
      }
      &:hover {
        color: #474849;
      }
    }
  }

  .secondary-nav-wrapper, .left-fixed-column, .element-gallery, .question-config {
    background-color: #F6F5FA;
  }

  main {
    background-color: white;
    position: sticky;
    top: 50px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    color: $title_text_grey;
  }

  .share-page, .submissions-page, .integrations-page, .builder-analytics-page, .profile-edit, .org-edit, .choose-plan {
    background-color: #FAFAFA;
  }

  .share-page, .submissions-page, .integrations-page, .builder-analytics-page {
    height: 100%;
  }

  .profile-edit, .org-edit, .choose-plan, .forms-pages-container {
    flex: 1 1 auto;
  }

  .profile-edit {
    .card {
      margin-bottom: 2.2rem;
    }
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .org-edit, .choose-plan, .share-page, .integrations-page, .builder-analytics-page {
    .center-column {
      @media only screen and (min-width: 1400px) {
        width: 1340px;
      }
    }
  }

  #form-select, .edit-name-input {
    width: 200px;
    margin-right: 0.8rem;
  }

  .edit-name-input {
    height: 38px;
  }

  .edit-name-save-btn {
    margin-right: 17.5px;
  }

  .alert-info {
    background: #f8f9fa;
    color: #0267BF;
    border: 1px solid #0267BF;
    a {
      color: #014c8d;
    }
  }

  .exit-preview-row {
    background-color: white;
  }

  .build-page {
    .form-wrapper {
      font-size: 16px;
      #zuko-builder-form {
        &.default-font {
          font-family: Verdana, sans‑serif;
          word-spacing: -2px;
          letter-spacing: 0.15px;
        }
        line-height: 1.5;
      }

      padding: 5px 15px 15px 15px;

      display: flex;
      flex-direction: column;
      align-items: center;

      // Override styles which the app is imposing
      h2, h1 {
        font-size: revert;
        margin-top: revert;
        margin-bottom: revert;
        font-weight: revert;
        line-height: inherit;
      }
    }
  }

  .share-page, .submissions-page, .integrations-page, .builder-analytics-page {
    .card {
      margin-bottom: 2.2rem;
      .card-body {
        padding: 20px 2rem 20px 2rem;
        .card-title-row {
          height: 44px;
        }
      }
    }
  }

  .alert-success, .alert-outline-success {
    .alert-text {
      padding: 4px 8px;
      font-size: 1rem;
    }
  }

  .preview-mode {
    background-color: white;
    .sticky-container {
      overflow-y: auto;
    }

    .toggle-device-view-row {
      right: 0;
      margin-top: -38px;

      .toggle-buttons {
        z-index: 1;
      }

      .builder-outline-secondary {
        padding: 5px 10px;
        background-color: white;
      }

      .btn-check:checked+.builder-outline-secondary {
        background-color: #7d7d7d;
        border-color: #7d7d7d;
        color: white;
      }
      .btn-check+.builder-outline-secondary:hover {
        border-color: #7d7d7d;
        background-color: #7d7d7d;
        color: white;
      }
    }

    .scrollable-column {
      min-height: calc(100vh - 143px);
      max-height: calc(100vh - 143px);
      padding: 0px 15px 15px 15px;
    }

    .form-wrapper {
      padding: 15px;
    }

    .scrollable-column > div:not(.preview-mobile-device) {
       footer {
          margin-bottom: 15px;
       }
    }

    .preview-mobile-device {
      border-radius: 50px;
      padding: 72px 20px;
      background: #333333;
      border: 1px solid #ccc;
      margin: auto;
      position: relative;
      transform: scale(90%);

      .form-wrapper {
        container-type: inline-size; // Use cqw for responsive sizing to this container
        border: 1px solid #ccc;
        width: clamp(290px, 22vw, 320px);
        height: clamp(420px, 50vh, 500px);
        overflow-y: auto;
        background-color: white;
      }

      #zuko-builder-form {
        width: 100%;
        .form-card, .completion {
          width: 100%;
          min-width: unset;
        }
      }
    }
  }
}

.builder-primary-cta, .builder-outline-primary-cta, .btn-in-nav {
  padding-top: 8px;
  padding-bottom: 8px;
}

.builder-primary-cta {
  background-color: #0267BF;
  border-color: #0267BF;

  &:focus, &:hover, &:not(:disabled):not(.disabled):active {
    background-color: #014c8d;
    border-color: #014c8d;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(2,103,191, 0.35);
  }
  &:disabled {
    background-color: #315f88ba;
    border-color: #0267bf9c;
  }
}

.builder-outline-primary-cta {
  border-color: #0267BF;
  color: #0267BF;
  background-color: white;

  &:hover, &:not(:disabled):not(.disabled):active {
    color: #014c8d;
    border-color: #014c8d;
    background-color: #f0f8ff;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(2,103,191, 0.35);
  }
}
