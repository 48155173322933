.print-only {
  display: none;

  @media print {
    display: block !important;
  }
}

.browser-only {
  @media print {
    display: none !important;
  }
}

@media print {
  @page {
    margin: 5mm;  // Maximise the page space by setting the print margins
  }

  // Freshchat widget
  #ember3, #fc_frame { // dev id, prod id
    display: none !important;
  }

  body {
    -webkit-print-color-adjust: exact !important;  /* Chrome, Safari */
    print-color-adjust: exact !important;  /* Firefox */
    color: #575962 !important;
    background-color: #fafafa !important;
    h1 {
      color: #575962 !important;
    }
  }

  .page-title {
    color: #50355e !important;
    font-size: 1.75rem;
    font-weight: 500;
    margin-top: 6px;
    margin-bottom: 0;
  }

  .selected-filters {
    border-radius: 2px;
    font-size: 1rem;
    padding: 5px;
    background: #ebedf2;
  }

  .page-header:not(:first-of-type) {
    break-before: always !important;  // Include a page header and page break before each visualisation
  }

  .flip-card-front {
    padding: 10px !important;
  }

  #scrollable-custom-events {
    max-height: none !important;
    overflow: visible !important;
  }

  #vsca, #custom-events-total {
    .flip-card-front {
      padding-right: 20px !important;
    }
  }

  .scrollable-chart-wrapper {
    max-height: none !important;
    overflow: visible !important;
    display: block !important;
  }

  .table-wrap {
    max-height: none !important;
    overflow: visible !important;
    display: block !important;
  }

  .main-content {
    padding-top: 0 !important;
  }

  .first-grid-row {
    margin-top: 70px !important;
  }

  .print-as-grid {
    flex: 0 0 50% !important;
    max-width: 50% !important;
    padding-right: 1rem !important;
  }

  #vsca-split-by-attr .scrollable {
    max-height: none !important;
    overflow: visible !important;
    display: block !important;
  }

  #abandoned-fields-overview, #field-returns-overview, #field-times-overview, #custom-events-overview, #field-flow-vis {
    .table-header-row {
      th {
        background-color: whitesmoke !important;
        border: 1px solid whitesmoke !important;
      }
    }
    tbody {
      td {
        border-bottom: 1px solid whitesmoke !important;
        border-left: 1px solid whitesmoke !important;
        border-right: 1px solid whitesmoke !important;
      }
    }
    .table th, .table td {
      padding: 0.5rem !important;
    }
  }

  #segment-comparison-page {
    @page {
      size: A4 landscape !important;
    }

    #segment-comparison-data {
      max-width: none !important;
      overflow: visible !important;
      display: block !important;
    }
  }
}
