@import '../Colours.scss';

#weekly-summary-unsubscribe-page {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .title-row {
    margin-bottom: 30px;
    min-height: 39px;
    h1 {
      padding: 7px 25px 7px 0;
      margin-bottom: 0;
    }
  }

  p {
    color: #3f4047;
  }
}
