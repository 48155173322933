.form-builder {
  .theme {
    padding: 10px 15px;
    h5 {
      border-bottom: 1px solid #d7dbe5;
      padding: 5px 0px;
    }
    label {
      color: #3f4047;
    }

    .input-feedback {
      font-size: 0.9rem;
      color: grey;
    }
  }
}
