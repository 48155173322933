@import '../../Colours.scss';

.build-page {
  .element-gallery, .theme {
    &.scrollable-column {
      min-height: calc(100vh - 147px);
      max-height: calc(100vh - 147px);

      &::-webkit-scrollbar {
        width: 7px;
      }
    }
  }
  .question-config {
    &.scrollable-column {
      min-height: calc(100vh - 95px);
      max-height: calc(100vh - 95px);
    }
  }
  .scrollable-column {
    overflow: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .top-sticky-row {
    position: sticky;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: transparent;
    &.solid-background {
      z-index: 3;
      background: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255, 1) 20%);
    }
  }

  .edit-form-area {
    background: white;
    .scrollable-column {
      min-height: calc(100vh - 146px);
      max-height: calc(100vh - 146px);
      padding: 0px 15px 15px 15px;
      &::-webkit-scrollbar {
        width: 7px;
      }
    }
  }

  .new-form-alert {
    font-size: 1.1rem;
    h2 {
      font-weight: 300;
    }
    padding: 1.5rem;
    width: fit-content;
    margin-top: 60px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .alert-row {
    min-height: 60px;
  }

  .alert-success, .alert-outline-success {
    .spinning-icon {
      width: 26px;
    }
  }

  .bottom-sticky-row {
    position: sticky;
    bottom: 0;
    z-index: 3;
  }

  .text-link-in-nav {
    font-size: 15px;
  }

  .feedback-wrapper {
    right: 0;
    z-index: 1;
  }

  .step-actions-row {
    background: linear-gradient(180deg, #efeef6, #f9f8fb 30%);
    padding: 6px;
    .btn-style-removed {
      all: unset;
      cursor: pointer;
      height: fit-content;
      &:focus {
        outline: revert;
      }
    }
    .changing-step-ctas {
      font-size: larger;
      &:hover {
        color: #474849;
      }
      &:disabled {
        pointer-events: none;
        opacity: 0.65;
      }
    }

    .step-action-btns {
      border-color: #00B3C7;
      color: #00B3C7;
      background-color: white;
      height: fit-content;

      &:hover, &:not(:disabled):not(.disabled):active {
        color: white;
        background-color: #00B3C7;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(2,103,191, 0.35);
      }
    }
  }
}
