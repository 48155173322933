#sign-up-page {
  font-family: 'Montserrat',sans-serif;
  color: #54565f;

  .center-column {
    padding: 20px 28px;
    min-height: 590px;
  }
  #login-container {
    margin: 0 15px;
    #login-container-col {
      width: 450px;
    }
    .submit-btn {
      color: white;
      min-width: 100px;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      font-size: 1.1em;
      padding: 10px 15px;
      width: fit-content;
      background: linear-gradient(to top, rgb(181, 38, 86), rgb(213, 57, 109) 15%);
      &:hover {
        background: linear-gradient(to top, hsl(340,65%,43%),hsl(340,65%,48%) 15%);
      }
      margin-top: 0;
    }

    form {
      padding-top: 20px;
      line-height: 1.5;
    }

    .login-form {
      label {
        color: #303136;
        font-size: clamp(17px, 6.25vw, 1.25em);
        margin-bottom: 5px;
      }
      .form-control {
        background: white;
        color: #776e87;
      }
    }

    .form-group, fieldset {
      margin-bottom: 20px;

      .form-control {
        border: 1px solid #6c6f7a;
        box-shadow: 0 0px 2px 0px rgba(108, 111, 122, 0.3), inset 0 0px 2px rgba(108, 111, 122, 0.6);
        border-radius: 5px;
        padding: 7px 10px;
        color: #303136;
        transition: all 0.3s ease-in-out;
        font-size: 1.2em;
        &.is-valid:focus {
          border-color: var(--bs-form-valid-border-color);
          box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
        }
        &.invalid-input {
          border-color: #DC3545;
          box-shadow: 0 0 2px 0px rgba(220, 53, 69, 0.3215686275), inset 0 0 2px 0px rgba(220, 53, 69, 0.3215686275);
          transition: 200ms linear;
        }
      }

      .valid-input-icon {
        color: #64ba7f;
        opacity: 0;
        margin-right: -32px;
        margin-left: 4px;
      }

      &.valid {
        .valid-input-icon {
          animation: FadeIn 0.2s linear forwards;
          @keyframes FadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        }

        .form-control.is-valid, .form-control:valid {
          border: 1px solid #64ba7f;
        }
      }
    }

    $checkbox-size: 36px;
    $checkbox-label-padding-left: 10px;

    .single-checkbox {
      .checkbox-item {
        display: flex;
        position: relative;
        min-height: $checkbox-size;
        padding-left: $checkbox-size;

        input[type=checkbox] {
          cursor: pointer;
          position: absolute;
          z-index: 1;
          top: 0px;
          left: 0px;
          width: $checkbox-size;
          height: $checkbox-size;
          margin: 0;
          opacity: 0;

          // When checked - colour the box
          &:checked+label:before {
            background-color: #3c2e60;
            border-color: #3c2e60;
          }
          // When checked - display the tick
          &:checked+label:after {
            opacity: 1;
          }

          &:focus+label:before {
              outline: 2px solid #0e5fcd;
          }
        }

        label {
          margin-bottom: auto;
          margin-top: auto;
          padding-left: $checkbox-label-padding-left;
          cursor: pointer;
          &.editable-inline {
            cursor: text;
          }
          font-size: clamp(17px, 6.25vw, 18px);

          // The box:
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: $checkbox-size;
            height: $checkbox-size;
            border: 1px solid #6c6f7a;
            box-shadow: 0 0px 2px 0px rgba(108, 111, 122, 0.3), inset 0 0px 2px rgba(108, 111, 122, 0.6);
            border-radius: 5px;
            transition: all .2s ease-in-out;
          }

          // The tick:
          &:after {
            content: "";
            position: absolute;
            top: 9px;
            left: 5px;
            width: 26px;
            height: 13px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            border: solid;
            border-width: 0 0 4px 4px;
            opacity: 0;
            color: white;
          }
        }
      }

      .other-input {
        margin-left: calc($checkbox-size + $checkbox-label-padding-left);
        margin-right: 26px;
      }
    }
    .checkbox-feedback {
      padding-left: calc($checkbox-size + $checkbox-label-padding-left);
      margin-top: 1.5px;
      margin-bottom: 0px;
    }
  }

  .awards-row {
    .award-logo {
      width: 25%;
      max-width: 100px;
    }
    p {
      color: #303136;
      font-size: clamp(15px, 5vw, 1em);
    }
  }

  .login-title {
    h1 {
      font-size: clamp(18px, 1.4vw, 20px);
      font-weight: 600;
      color: #524c5d;
    }
  }

  .alert-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    .alert-svg-icon {
      max-width: 32px;
    }
    .alert {
      margin-bottom: 0;
    }
  }

  .signup-info-title {
    margin-top: 15px;
    h3 {
      font-size: clamp(14px, 3vw, 17px);
    }
  }

  .signup-info {
    p {
      font-size: 16px;
    }
  }

  .alert-danger, .alert-success {
    .alert-text {
      padding: 1.45rem 0rem 1.45rem 1.25rem;
    }
  }

  .invalid-feedback, .valid-feedback {
    display: block;
    margin-top: -5px;
    margin-bottom: 5px;
    font-size: clamp(15px, 5vw, 1em);
  }

  .bold-msg {
    font-weight: 600;
    font-size: 1rem;
  }

  .feedback-info {
    color: #54575f;
  }

  .login-link {
    color: #6a6379;
    font-size: 1.1rem;
  }

  .divider {
    max-width: 340px;
    height: 5px;
    margin: 20px auto;
    border-radius: 20px;
    background-image: linear-gradient(145deg,#9e184d,#ed2c5d 27%,#ffba56);
  }

  .logos {
    display: grid;
    grid-column-gap: 15px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    max-width: 800px;
    img {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }
  .logo-text-row {
    p {
      color: #303136;
    }
  }

  .signup-divider {
    margin: 10px 0;
    hr {
      width: 45%;
    }
    p {
      font-size: 1rem;
    }
  }

  .oauth-container {
    max-width: 450px;
  }
}

#hint-tooltip {
  .tooltip-inner {
    max-width: 100%;
    padding: 0.3rem 0.5rem;
    color: #60636C;
    background-color: #f7e2bc;
    border: 2px solid #fc9a4e;
  }

  .tooltip-arrow::before {
    border-top-color: #fc9a4e;
  }
}
