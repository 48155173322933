@import '../../Colours.scss';

#tracking-code-wizard {
  padding: 20px 10px 10px 10px;
  border-radius: 0.25rem;
  box-shadow: 0 1px 6px 1px #eae3f7;
  border-radius: 5px;

  .btn-primary {
    background-color: #16aca4;
    border-color: #17aba375;
  }

  .alert {
    font-size: 1.1rem;
  }

  .progress-bar-container {
    width: 60%;
    min-width: 300px;
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
    .step-name {
      white-space: nowrap;
    }

    @media only screen and (max-width: 780px) {
      .step-name {
        display: none;
      }
    }
  }

  .tab-nav-column {
    border-right: 1px solid #eaecef;
  }

  .nav-tabs {
    border:unset;
    .nav-link {
      padding: 4px 8px;
      color: #858893;
      font-size: 1.1rem;
      border: 1px solid #17aba375;
      &.active, &:hover {
        color: $title_text_grey;
          background-color: #eaf5f5;
      }
    }
  }

  .tab-content {
    border-radius: 0.1rem;
    border: 1px solid #17aba375;
    padding: 10px;
    .fade {
      transition: opacity 500ms linear;
    }
  }

  .copy-code-button {
    padding: 6px 12px;
    min-width: 86px;
  }

  .codeExample {
    pre {
      padding: 10px;
      margin: 0;
      max-width: fit-content;
      border: 1px solid #eee;
      background-color: #fafafa;
    }
  }

  .form-check {
    input[type=checkbox] {
      filter: hue-rotate(18deg);
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }

  .actions-row {
    border-top: 1px solid #ebedf2;
    padding-top: 20px;
  }

  .list-title {
    font-size: 1.1rem;
    font-weight: 500;
  }

  h4 {
    font-weight: 300;
    font-size: 18px;
  }

  .text-more-info {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: #c9c9c9;
    &:hover {
      color: #43454c;
      text-decoration-color: #43454c;
    }
  }
}
