@import '../../Colours.scss';
.sessions-container {
  background: $light_grey;
  padding: 10px;
  display: inline-flex;
  width: 100%;
  align-items: flex-start;

  .session-container {
    color: $dark_text_grey;
    box-shadow: 0 1px 8px 1px rgba(150, 150, 150, 0.2);
    border-radius: .25rem;
    width: 280px;
    background: white;
    padding: 10px;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;

    &:last-of-type {
      margin-right: 0;
    }

    dl {
      margin-bottom: 0;
    }

    dd {
      margin-bottom: 0;
      margin-left: 5px;
      line-height: normal;
    }

    dt {
      font-weight: 500;

      &::after {
        content: ': ';
      }
    }

    dt, dd {
      display: inline-block;
    }
    .custom-events-included-msg {
      background-color: #ebecff;
      border-radius: 0.25rem;
      border: 1px solid #e5e7ff;
      padding: 0 2px;
    }

    .header-box {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed grey;

      .index {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .footer-box {
      border-top: 1px dashed grey;
      margin-top: 10px;
      padding-top: 10px;

      .index {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }

    .flurry-list {
      .flurry {
        background-color: #f2f1f4;
        box-shadow: 0 1px 2px 1px rgba(231, 229, 232, 0.4);
        padding: 10px;
        border-radius: .25rem;
        position: relative;

        h5 {
          background-color: #e3e1e5;
          border-radius: .25rem;
          padding: 5px;
          word-wrap: break-word;
        }

        &.selectedField {
          h5 {
            background-color: rgba(244, 250, 90, 0.8)
          }
        }

        &.field-return {
          box-shadow: 0px 0px 5px 2px rgba(255, 0, 0, 0.7);
        }

        .return-count {
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: red;
        }
      }

      .flurry.field-return + .custom-event {
        margin-top: 2px;
      }

      .flurry-separator {
        text-align: center;
        margin: 10px;

        i {
          margin-right: 5px;
        }
      }

      .custom-event {
        padding-top: 5px;
        h5 {
          background-color: #ebecff;
          box-shadow: 0 1px 2px 1px rgba(169, 185, 239, 0.2);
          border-radius: .25rem;
          padding: 5px;
          word-wrap: break-word;
        }
      }
    }
  }
}

input.session-query-sm {
  width: 55px;
}

.session-explorer {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .query-nav-wrapper {
    position: relative; // No need to have a stick query nav due to the vertical length of sessions
  }

  .custom-attributes-wrapper {
    border: solid 1px #d5dbe1;
    border-radius: 0.25rem;
    padding: 2px 4px;
    .data-title {
      font-weight: 500;
    }

    .badge {
      text-wrap: wrap;
      text-align: left;
      word-break: break-all;
    }
  }
  .view-replay-cta {
    padding: 2px 6px;
    font-size: 13px;
    color: #6950a5;
    background-color: white;
    border-color: #4f3d7f;
    line-height: normal;
    &:active, &:focus, &:hover, &:not(:disabled):not(.disabled):active {
      color: white;
      background-color: #6950a5;
      border-color: #4f3d7f;
    }
  }
  .scroll-wrapper {
    flex-grow: 1;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .main-content {
    padding-top: 10px;
  }

  .alert-row {
    padding-left: 10px;
    padding-right: 14px;
  }

  .stick-in-place {
    position: sticky;
    left: 0;
  }

  .custom-events-alert {
    margin-top: -10px;
    width: fit-content;
    .alert {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      background-color: #ebecff;
      border: 1px solid #e5e7ff;
      color: $text_grey;
      a {
        color: $text_grey;
      }
    }
  }

  .title {
    &.hide {
      z-index:1;
    }
    .title-tooltips {
      max-width: fit-content;
    }

    .url-copy-icon {
      margin-right: 5px;
    }
    .settings-icon {
      margin-right: 8px;
    }
  }

  .page-info-card {
    width: unset;
    margin: 10px 2rem 10px 2rem;
    box-shadow: 0 0 15px 4px rgba(113,106,202,.2);
    position: absolute;
    background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
    padding: 2px;
    .card-contents {
      background-color: white;
      max-height: 500px;
      padding: 20px 2rem 20px 2rem;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
          .card-title {
            margin-bottom: 0;
            font-weight: 200;
            color: #575962;
          }
        }
      }
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .text-content {
        overflow-y: auto;
      }

      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
        h4 {
          font-weight: 500;
          font-size: 1.5rem;
        }
      }
      .info-pointer {
        color: #62656f;
      }
    }

    &.open {
      z-index: 4;
      animation: SessionExplorerFadeIn 0.3s linear forwards;
    }
    &.closed {
      z-index: -1;
      animation: SessionExplorerFadeOut 0.2s ease-out forwards;
    }
    @keyframes SessionExplorerFadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes SessionExplorerFadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  .original-content {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &.background {
      opacity: 0.3;
    }
  }

  .card-tooltip, .title-tooltips {
    max-width: fit-content;
    .info-circle-icon {
      cursor: pointer;
      color: #a291ca;
      transition: all .2s ease-in-out;
      &:hover {
        color: #6950a5;
        transform: scale(1.1);
      }
    }
  }

  #datepicker {
    min-width: 30%;
  }
}

input[class^="session-query-"] {
  border: 1px solid #EBEDF2;
  border-radius: 3.5px;
  padding: 2px 4px;
}
