#set-password-page {
  .center-column {
    padding: clamp(15px, 10%, 50vh) 2rem 5% 2rem;
  }
  #login-container {
    margin: 0 15px 4rem 15px;
    #login-container-col {
      width: 450px;
    }
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
}
