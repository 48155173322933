@import '../variables';

#zuko-builder-form {
  color: $text_black;

  .form-card {
    background: white;
    width: 45vw; // Fixes the width better than %. NB. Stops the invalid feedback p tags from widening the area
    max-width: 800px;
    min-width: 500px;

    border-radius: $border-radius;
    padding: 20px 30px;

    @media only screen and (max-width: 500px){
      width: 100%;
      min-width: unset;
    }

    box-shadow: #dedede 0px 2px 8px 0px;

    h1 {
      font-size: clamp(18px, 7cqw, 1.7em);
      color: var(--accent-color);
    }

    h2 {
      color: var(--accent-color);
      font-size: clamp(18px, 6.5cqw, 1.5em);
    }

    p, label, legend, h1, h2 {
      overflow-wrap: anywhere;
      white-space: normal;
    }

    p {
      &.paragraph-text, &.form-description, &.step-description {
        margin-bottom: 15px;
      }
      font-size: clamp(15px, 5cqw, 1em);
    }

    hr {
      border-color: #303136;
      opacity: 0.50;
    }
  }
  .editable-inline {
    display: inline-block;
    outline: 0;
    cursor: default;
  }
  &.in-edit {
    .form-card {
      min-width: 350px;
    }
    label.editable-inline.in-error {
      width: fit-content;
    }
    .editable-inline {
      user-select: text;
      cursor: text;
      span.text-cursor {
        cursor: text;
      }
      a {
        position: relative;
        display: inline-flex;
      }
    }
    .editable-inline.in-error:after {
      content: 'Enter label';
      color: grey;
    }
    .single-checkbox .editable-inline.in-error:after {
      // Unset all the styles which are actually used to position the tick
      opacity: 1;
      position: relative;
      left: auto;
      top: auto;
      border: 0;
    }
    .input-success-wrapper:has(.select-container), .radio-button-option, .checkbox-group .checkbox-item, .optional-tag-container .optional-tag {
      position: relative;
    }
    a:hover:after, .input-success-wrapper:has(.select-container):hover:after, .radio-button-option:hover:after,
    .optional-tag-container .optional-tag:hover:after, .checkbox-group .checkbox-item:hover:after {
      background: #f8f9fa;
      color: #0267BF;
      border: 1px solid #0267BF;
      width: fit-content;
      white-space: nowrap;
      border-radius: 5px;
      z-index: 3;
      padding: 2px 4px;
      position: absolute;
      bottom: -25px;
      font-size: 12px;
    }
    a:hover:after {
      content: 'Open settings to edit the link';
      left: 50%;
      margin-left: -75px;
    }
    .input-success-wrapper:has(.select-container):hover:after {
      content: 'Open settings to manage options in the dropdown';
      left: 0;
      margin-left: 0;
    }
    .optional-tag-container:not(.address-2-tag) .optional-tag:hover:after {
      content: 'Open settings to remove optional';
      left: 0;
      margin-left: -60px;
    }
  }
}
