@import '../Colours.scss';

.funnel-builder {
  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .funnel-table-wrap {
    overflow-x: scroll;
    // NB. overflow-y: visible doesn't work as expected - it is changed to 'auto' - so the menu stays inside the overflow area. Alternative is to set the menuPortalTarget on document.body.
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  h5 {
    font-weight: 500;
  }

  .url-copy-icon {
    margin-right: 5px;
  }

  .invalid-input {
    border: 2px solid rgba(220, 53, 69, 0.8);
    box-shadow: 0 0 4px 0.1rem rgba(206, 212, 218,0.9);
  }

  .invalid-input-feedback {
    font-size: 80%;
    color: #dc3545;
  }

  // TODO: check placeholder in other browsers
  .form-control::placeholder {
    color: white;
    opacity: 0.4;
  }

  svg.disabled {
    color: lightgrey;
    pointer-events: none;
  }

  .trash-wrap.disabled {
    pointer-events: none;
  }

  svg.keep-icon-hover {
    color: #3f4047;
  }

  #funnel-vis {
    min-height: 353px;
  }

  .funnel-builder-table {
    table-layout: auto;
    color: #575962;
    .grip-column, .actions-column {
      width: 3%;
      padding: 0;
    }
    .disabled-drag {
      color: #9e9e9e;
      pointer-events: none;
    }
    .funnel-column {
      width: 45%;
      min-width: 450px;
      height: 1px;
      padding-top: 5px;
    }
    .fields-column {
      width: 49%;
      max-width: 650px;
    }
    .plus-inside-btn {
      margin-bottom: 0.2rem;
    }
    .funnel-step-row {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-between;
      color: white;
      flex-grow: 1;
      padding: 10px 0;
      min-height: 80px;
      transition: all .2s ease-in-out;

      .funnel-step-name {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .funnel-step-value {
        font-size: 1.4rem;
      }
    }

    .stage-name-input {
      min-width: 90px;
      padding: 0.25rem 0.4rem;
      color: white;
      background-color: rgba(244,236, 247, 0.2);
      text-align: center;
    }

    div[class^="colour"] {
      transition: all .2s ease-in-out;
    }

    .colour1, .colour6 {
      background-color: #611e79;
      &:hover {
        background-color: #561b6b;
      }
    }
    .colour2, .colour7 {
      background-color: #820b8a;
      &:hover {
        background-color: #730a7a;
      }
    }
    .colour3, .colour8 {
      background-color: #BB3087;
      &:hover {
        background-color: #ad2d7d;
      }
    }
    .colour4, .colour9 {
      background-color: #f45583;
      &:hover {
        background-color: #f34577;
      }
    }
    .colour5, .colour10 {
      background-color: #fd8d3c;
      &:hover {
        background-color: #fd832b;
      }
    }

    .colour-completion {
      background-color: #0267BF;
      &:hover {
        background-color: #025eae;
      }
    }

    .funnel-bucket {
      width: 95%;
      height: 100%;
    }

    .funnel-end-bucket {
      width: 95%;
      height: 100%;
    }

    .bottom-border-cell {
      border-bottom: 2px solid #dee2e6;
    }

    .funnel-step-metric {
      font-weight: 400;
    }

    .funnel-row {
      color: #737684;
    }

    .metric-value {
      padding: 2.5rem 0 0.5rem 0;
      text-align: center;
      font-size: 1.2rem;
    }

    .funnel-wrap {
      filter: drop-shadow(0px 4px 3px rgba(77, 12, 161, 0.5));
      height: 100%;
      width: 100%;
      display: inline-table;
    }

    tbody {
      tr:not(:last-child) {
        td {
          &.metric-value, &.fields-column, &.actions-column {
            border-bottom: 1px solid #dee2e6b3;
          }
        }
      }
      tr {
        border: none;
        &.keep-row-hover {
          background-color: #fafafa;
        }
        &.funnel-row:hover {
          background-color: #fafafa;
          .colour1 {
            background-color: #561b6b;
          }
          .colour2 {
            background-color: #730a7a;
          }
          .colour3 {
            background-color: #ad2d7d;
          }
          .colour4 {
            background-color: #f34577;
          }
          .colour5 {
            background-color: #fd832b;
          }
        }
        &.funnel-row:last-child {
          .fields-column, .actions-column, .metric-value {
            border-bottom: 2px solid #dee2e6;
          }
        }
        td {
          border: none;
          vertical-align: middle;
        }
      }
    }

    th {
        border-bottom: 0;
        font-weight: 400;
        font-size: 1.1rem;
    }

    &.bottom-menu-open {
      margin-bottom: 110px;
    }
    &.second-to-bottom-menu-open {
      margin-bottom: 30px;
    }
  }

  .back-btn {
    background-color: white;
    color: #00c5dc;
    border-color: #00c5dc;
    &:hover {
      color: #00a7ba;
      border-color: #00a7ba;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0,167,186, 0.5);
    }
    &:disabled  {
      background-color: #f9f9f9;
      border-color: #fff;
      color: #9e9e9e;
    }
  }

  .progress-area {
    display: flex;
    align-items: center;
    height: 351px;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
    p {
      height: 20px;
    }
  }

  .card {
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 1.5rem 10px 1.5rem;
      .card-title-row {
        height: 44px;
        padding-left: 0.5rem;
      }
      .card-vis {
        padding-bottom: 1rem;
        display: flex;
        flex-direction: column;
        min-height: 280px;
      }
    }
  }

  .first-funnel-info {
    background: #f8f9fa;
    color:#0267BF;
    border: 1px solid #0267BF;
    padding: 0.75rem;
  }

  .flip-card {
    .card {
      background-color: transparent;
    }
    perspective: 1000px;
    background-color: transparent;
    margin-bottom: 2.2rem;

    .card-body {
      z-index: -1;
    }

    .card-tooltip {
      max-width: fit-content;
      .info-circle-icon {
        cursor: pointer;
        color: #a291ca;
        transition: all .2s ease-in-out;
        &:hover {
          color: #6950a5;
          transform: scale(1.1);
        }
      }
    }

    .flip-card-inner {
      transition: transform 0.6s;
      transform-style: preserve-3d;
        &.perform-flip  {
          transform: rotateX(180deg);
        }
      }

    .flip-card-front, .flip-card-back {
      min-width: 100%;
    }

    .flip-card-front {
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: white;
      padding: 20px 2rem 0rem 2rem;
      transform: rotateX(0deg);
      opacity: 1;
      z-index: 2;
      &.not-visble {
        animation: fadeOut 0.2s linear forwards;
      }
      &.visible {
        z-index: 2;
        animation: fadeIn 0.2s linear forwards;
      }
    }

    .flip-card-back {
      position: absolute;
      bottom: 0;
      background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
      padding: 2px;
      -moz-transform: rotateX(180deg);
      transform: rotateX(180deg);
      height: 100%;
      &.not-visble {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        animation: fadeOut 0.3s linear forwards;
      }
      opacity: 0;
      &.visible {
        animation: fadeIn 0.3s ease-in forwards;  // Animation (as opposed to transition) improves the flip in Chrome as we're having to set z-index
        z-index: 2; // Required for links to be clickable in Chrome
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    .card-tagline {
      font-weight: 400;
      font-size: 1.1rem;
    }

    .text-more-info {
      text-decoration: underline;
      text-decoration-style: dotted;
      &:hover {
        color: #6c6f7a;
      }
    }

    .card-contents {
      background-color: white;
      padding: 18px 26px 22px 26px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      h4 {
        font-weight: 500;
        font-size: 1.3rem;
      }
      .text-content {
        overflow-y: auto;
      }
      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
              .text-bold {
          font-weight: 500;
        }
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
      }
    }

    #Funnel-Chart {
      height: auto;
      width: 75%;
      max-width: 600px;
    }
  }

  #datepicker {
    min-width: 30%;
  }
}

#disabled-add-stage-tooltip {
  .tooltip-inner {
    background-color: #9e9e9e;
  }
  .tooltip-arrow::before {
    border-top-color: #9e9e9e;
  }
}

#delete-stage-tooltip {
  left: -40px !important;
  .tooltip-arrow {
    left: 40px !important;
  }
}

.stage-name-badge {
  margin-right: 5px;
  right: 0;
  position: absolute;
}

.option-badge {
  padding: 4px;
  border-radius: 4px;
  color: #a6a6a6;
  background: #e6e6e6;
}

.option-not-selectable {
  padding: 4px;
  border-radius: 4px;
  color: #ec5656;
  background: #f8e4e4;
}


.metric-popover {
  .popover-body {
    max-width: 240px;
  }
}
