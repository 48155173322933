@import '../../Colours.scss';

.player-navigate-row {
  a {
    text-decoration: none;
    color: $title_text_grey;
  }

  .btn-style-removed {
    all: unset;
    cursor: pointer;
    &:focus {
      outline: revert;
    }
    &:active {
      all: unset;
    }
    &:hover {
      color: #474849;
    }
  }
}

#player {
  height: 100%;
  .center-column {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .duration-msg-badge {
    border: 1px solid #c9cacf;
    padding: 0px 4px;
    border-radius: 0.25rem;
    transition: all .15s ease-in-out;

    &:hover, &:focus {
      background:linear-gradient(to top, #dfdfdf, #FFFFFF 15%);
    }
  }

  .card {
    flex-grow: 1;
    margin-bottom: 30px;
  }

  .rr-player {
    box-shadow: none;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: rgb(250 250 250);
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .rr-progress__handler {
    background-color: #6950a5;
    box-shadow: 1px 1px 3px 0px rgb(150 150 150);
  }

  .rr-controller__btns {
    button {
      border-radius: 0.25rem;
      &:hover {
        background-color: #dcd6eb;
      }
    }
    button.active {
      background-color: #6950a5;
      border-color: #4f3d7f;
    }
    .switch {
      input[type='checkbox']:checked+label:before {
        background-color: #6950a5;
      }
    }
  }

  .replayer-mouse:after {
    width: 25px;
    height: 25px;
    background: #6950a5;
  }

  #session-details-card {
    margin-left: 30px;
    max-width: 230px;
    max-height: calc(100vh - 200px);
    .scrollable-list {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, .5);
      }
    }
    .fit-content {
      width: fit-content;
    }
    h4 {
      font-weight: 700;
    }

    .attributes-icons {
      .badge {
        --bs-badge-font-size: 12px;
      }
    }
  }
}