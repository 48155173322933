@import '../Colours.scss';

#field-segment-comparison-page {
  .main-content {
    padding-top: 10px;
  }

  .pdf-export-icon {
    margin-right: 10px;
  }

  .csv-export-icon {
    margin-right: 7px;
  }
  .url-copy-icon {
    margin-right: 5px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    width: fit-content; // Allow the area to expand to full width if needed
  }

  row > * {
    width: unset;
  }

  .page-info-card {
    right: 0;
    box-shadow: 0 0 15px 4px rgba(113,106,202,.2);
    position: absolute;
    background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
    padding: 2px;
    .card-contents {
      background-color: white;
      max-height: 550px;
      padding: 20px 2rem 20px 2rem;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
          .card-title {
            margin-bottom: 0;
            font-weight: 200;
            color: #575962;
          }
        }
      }
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .text-content {
        overflow-y: auto;
      }

      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
        h4 {
          font-weight: 500;
          font-size: 1.5rem;
        }
      }
    }

    &.open {
      z-index: 4;
      animation: SegmentComparisonFadeIn 0.3s linear forwards;
    }
    &.closed {
      z-index: -1;
      animation: SegmentComparisonFadeOut 0.2s ease-out forwards;
    }
  }

  @keyframes SegmentComparisonFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes SegmentComparisonFadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  #datepicker {
    min-width: 30%;
  }

  .original-content {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &.background {
      opacity: 0.3;
    }
  }

  .info-circle-icon {
    max-width: fit-content;
    cursor: pointer;
    color: #a291ca;
    transition: all .2s ease-in-out;
    margin-left: 10px;
    &:hover {
      color: #6950a5;
      transform: scale(1.1);
    }
  }

  .card-tooltip {
    max-width: fit-content;
    color: $text_grey;
    &:hover {
      color: #3f4047;
    }
  }

  .add-comparison-link {
    cursor: pointer;
    color: #5867dd;
    &:hover {
      color: #2739c1;
    }
  }

  .comparison-icon {
    i {
      padding: 5px;
      font-size: 80%;
    }
  }

  #comparison-name {
    width: inherit;
    height: inherit;
    min-width: 181px;
    padding: 1px 4px;
    font-size: 19px;
    line-height: 1;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    color: $dark_text_grey;

    &.invalid-input {
      border-color: #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }

  #comparison-title {
    button {
      padding: 3px 10px;
    }
  }

  .error-alert {
    .alert-text {
      padding: 5px;
    }
    .close {
      padding: 5px 10px;
    }
  }

  #segment-comparison {
    min-height: 530px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw - 60px);

    @media only screen and (max-width: 1399px) {
      min-width: calc(100vw - 120px);
    }
    @media only screen and (min-width: 1400px) {
      min-width: 1280px;
    }
  }

  .progress-area {
    display: flex;
    align-items: center;
    height: 300px;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  #field-metric-select {
    width: 200px;
  }

  .card {
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 20px 2rem;
      .card-title-row {
        height: 44px;
      }
    }
  }

  .card-tagline {
    font-weight: 400;
    font-size: 1.1rem;
  }

  #MetricDropdownImg {
    height: auto;
    max-width: 300px;
  }
}
