@import '../Colours.scss';

.builder-analytics-page {
  #datepicker {
    max-width: fit-content;
    min-width: 350px;
    .datepicker-dates {
      margin-right: 8px;
    }
    .drp-buttons {
      display: none;
    }
  }

  .card-content {
    min-height: calc(100vh - 372px);
  }

  .progress-area {
    display: flex;
    align-items: center;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  .card-title-row {
    a {
      font-size: 1.1rem;
    }
  }

  .table-wrap {
    max-height: calc(100vh - 372px);
  }
}
