@import 'Colours';

.navbar.navbar-dark {
  font-size: 1.1rem;
  line-height: 18px;
  background: $dark_purple;
  --bs-navbar-padding-y: 0;

  .navbar-brand {
    margin-right: 10px;
  }

  .navbar-nav {
    @media(min-width: 992px) {
      align-items: center;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light_grey;
    border-bottom: 2px solid transparent;
    padding-left: 0.8rem;
    padding-right: 0.8rem;

    &:hover, &.active {
      border-bottom: 2px solid $light_grey;
      color: $light_grey;
      transition: all 0.2s ease-in;
      margin-bottom: 3px;
      text-decoration: none;
       &[aria-expanded="true"] {
        margin-bottom: 0;
      }
    }

    &:focus {
      color: $light_grey;
    }

    &.disabled, &.nav-link:disabled {
      opacity: 0.8;
    }

    &.dropdown-toggle {
      border-bottom: 2px solid transparent;
    }

    @media(max-width: 992px) {
      justify-content: start;
    }
  }

  &.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(245, 245, 245, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
  }

  .navbar-toggler {
    border-color: $light_grey;
  }

  .show-intro-js {
    &.next-step-4,  &.next-step-5, &.next-step-6 {
    .segments-dropdown {
      .dropdown-menu {
        display: block;
        z-index: 100 !important;
      }
    }
   }
   &.next-step-6, &.next-step-7, &.next-step-8, &.next-step-9 {
    .user-journeys-dropdown {
      .dropdown-menu {
        display: block;
        z-index: 99 !important;
      }
    }
   }
   &.next-step-9, &.next-step-10, &.next-step-11 {
    .monitoring-dropdown {
      .dropdown-menu {
        display: block;
        z-index: 98 !important;
      }
    }
   }
  }

  .more-dropdown {
    .dropdown-menu {
      .nav-link {
        justify-content: start;
      }
      .dropdown-toggle {
        margin-bottom: 0;
      }
    }
  }

  .dropdown-menu {
    border-radius: 0.25rem;
    white-space: nowrap;
    padding: 0.5rem;
    background: $dark_purple;
    border: 0;
    box-shadow: 8px 4px 15px 1px rgba(113,106,202,.2);
    font-size: 1.1rem;
    .dropdown-item {
      text-decoration: none;
      padding: 0.55rem 0.8rem;
      color: whitesmoke;
      font-size: 1.1rem;
      &:hover, &:focus, &:active, &.active {
        background-color: $dark_purple;
        &:not(#nav-switch-app-btn-link) {
          border-bottom: 2px solid $light_grey;
          color: $light_grey;
        }
      }
    }
    .dropdown-divider {
      border-top-color: #403167;
      border-top-width: 2px;
    }
  }

  .user-dropdown {
    .dropdown-menu {
      left: inherit;
      right: 0;
    }
  }

  .zuko-logo {
    background-image: url('./logo.png');
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .btn-less-padding {
    padding: 7px 15px;
  }

  .switch-app-btn {
    white-space: nowrap;
    background-color: #3c2e60;
    border-color: #5b498a;
    &:hover {
      background-color: #392b5a;
      border-color: #443768;
    }
  }
}

.intro-helper-layer {
  background-color: rgba(255,255,255,.4) !important; // Increases the transparency of the highlighted menu options
}

.introjs-tooltip.intro-reference-layer, .introjs-tooltip.first-tooltip, .introjs-tooltip.last-tooltip {
  left: 0px !important; // Aligns the tooltips to the element they're highlighting
}

#alerts-page, .session-explorer, #segment-comparison-page {
  .info-circle-icon.introjs-showElement {
    color:#f0e9ff; // So that the info icon is more visible through the grey overlay
  }
}

.introjs-tooltip.first-tooltip {
  margin-left: 10px;
}

.introjs-tooltip.last-tooltip .introjs-tooltipbuttons .introjs-button.introjs-skipbutton {
  color: inherit; // Makes the 'Done' text stand out
}

.intro-reference-layer, .first-tooltip, .last-tooltip {
  ul.intro-list {
    padding-left: 15px;
  }

  .introjs-progress .introjs-progressbar {
    background-color: $dark_purple;
  }

  .introjs-tooltipbuttons {
    .introjs-button {
      background-image: none;
      text-shadow: none;
      background-color: #403267;
      border-color: #403267;
      color: white;
    }

    .introjs-prevbutton {
      background-color: #9e9e9e;
      border-color: #9e9e9e;
      color: white;
    }

    .introjs-disabled {
      background-color: #f4f4f4;
      border-color: #f4f4f4;
      color: #9e9e9e;
    }
  }

  .introjs-tooltipbuttons a.introjs-button:hover:not(.introjs-disabled) {
    color: white;
    background-color: $dark_purple;
    background-image: none;
    text-shadow: none;
  }

  .introjs-tooltiptext {
    padding: 0px 15px 0px 15px;
    font-size: 1.1rem;
  }
}

.navbar {
  sup {
    top: -1em;
  }

  .beta-tab-badge {
    padding: 0.25em 0.4em;
    background: #5867dd;
    margin-right: -10px;
  }

  .text-with-beta {
    margin-right: -20px;
  }

  .dropdown-item {
    sup {
      top: 0;
      vertical-align: middle;
    }

    .beta-tab-badge {
      padding: 0.25em 0.4em;
      margin-left: 5px;
    }

    .text-with-beta {
      margin-right: 0;
    }
  }
}
