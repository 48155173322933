.form-builder {
  .left-fixed-column {
    max-width: 250px;
    border-right: 1px solid #7d7d7d;
  }

  .toggle-edit-type {
    --bs-border-color: #abafbb;
    .nav-item {
      --bs-nav-tabs-link-active-border-color: #abafbb;
      --bs-nav-tabs-border-radius: none;
      &:first-of-type {
        button {
          border-right-width: 1px;
          border-right-style: solid;
          border-image: linear-gradient(to top, transparent 0%, transparent 20%, #8d8f9a 20%, #8d8f9a 80%, transparent 80%) 0 100% 0 0;
        }
      }
      width: 50%;
      button {
        width: 100%;
        color: #6e717c;
        border-left: 0;
        border-top: 0;
        border-right: 0;
        font-size: 1.75em;
        font-weight: 300;
        background: linear-gradient(0deg, #e5e2e9, #f0eef6 15%);
      }
      .nav-link {
        border-bottom: 0;
        margin-bottom: 0;
        transition: color 100ms linear;
        &:hover {
          color: #26272b;
        }
      }
      .nav-link.active {
        color: #26272b;
        border-bottom: 0;
      }
    }
  }
}
