#summary-field-data-table {
  .header-text-more-info {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 3px;
    text-decoration-color: #999999;
    &:hover {
      color: #303136;
      transition: text-decoration-color 200ms linear;
      text-decoration-color: #575962;
    }
  }

  #replay-checkbox-wrapper {
    input[type=checkbox] {
      filter: hue-rotate(18deg); // Change the native colour of the checkbox
      height: 15px;
      width: 15px;
      cursor: pointer;
    }

    sup {
      top: -1.2em;
    }

    .beta-tab-badge {
      padding: 0.25em 0.4em;
      background: #5867dd;
      margin-left: -10px;
    }
  }

  .text-more-info {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: #6c6f7a;
    &:hover {
      text-decoration-color: #54565f;
    }
  }

  .table-wrap {
    overflow: scroll;
    border: 1px solid #ebedf3;
    border-radius: 0.25rem;
    padding-left: 8px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .video-icon-wrap {
    .grey-icon:not(.page-link-icon-disabled) {
      color: #858893;
      &:hover {
        color: #6C6F7A;
      }
    }
  }

  table {
    --bs-table-hover-bg: #fafafa;

    thead {
      position: sticky;
      top: 0;
      z-index: 3;
    }
    tr {
      height: fit-content;
    }
    tr > th {
      white-space: unset;
      word-break: break-word;
    }
    th {
      font-size: 15px;
      background-color: #fff;
      padding: 0.5rem;
      margin: 0px 3px;
      border-bottom: 2px solid #dee2e6;
      .header-text-with-sort {
        margin-left: 18px;
      }
      &:first-of-type {
        margin: 0px 3px 0px 0px;
        .header-text-with-sort {
          margin-left: 0px;
        }
      }
      &:last-of-type {
        margin: 0px 0px 0px 3px;
      }
    }
    .table-body {
      tr {
        td {
          padding: 0.5rem;
          font-size: 14px;
          border-bottom: 1px solid #ebedf3;
          margin: 0px 3px;
          &:first-of-type {
            margin: 0px 3px 0px 0px;
          }
          &:last-of-type {
            margin: 0px 0px 0px 3xspx;
          }
        }
        &:hover {
          td {
            background-color: #fafafa;
          }
        }
        &:last-of-type {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .toggle-columns-btn {
    color: #878f97;
    border-color: #878f97;
    &:hover {
      background-color: whitesmoke;
      color: #575962;
      border-color: #575962;
    }
  }

  .table-sort-icons {
    padding-left: 2px;
    font-size: 1.1rem;
    .sort-up {
      margin-bottom: -0.5rem;
    }
    .sort-down {
      margin-top: -0.5rem;
    }
    .sort-up, .sort-down {
      color:#dcdcdc;
      line-height: 0.5rem;
      &.sorted {
        color: #7a80dd;
      }
    }
  }

  .abandoned-cell, .completed-cell {
    padding: 4px 6px;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  .abandoned-cell {
    background-color: #f9d2d4;
  }
  .completed-cell {
    background-color: #bcdaf6;
  }
}

#table-header-info-popover {
  font-size: 16px;

  .abandoned-colour-bar, .completed-colour-bar {
    display: inline-block;
    width: 20px;
    height: 10px;
    margin-right: 8px;
    border-radius: 0.25rem;
  }

  .abandoned-colour-bar {
    background-color: #f9b2b6;
  }

  .completed-colour-bar {
    background-color: #91c2ef;
  }

  h4 {
    font-size: 1.1em;
    font-weight: 300;
  }

  dl {
    font-size: 0.9em;
  }
}

#summary-table-column-toggle {
  .popover-arrow {
    display: none;
  }
  .popover-body {
    text-align: start;
    color: #575962;
    padding-bottom: 0px;
  }

  label {
    cursor: pointer;
  }
  input[type=checkbox] {
    filter: hue-rotate(18deg); // Change the native colour of the checkbox
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-top: 0;
  }
}