#invite-set-password-page {
  .center-column {
    padding: clamp(15px, 5%, 50px) 2rem 5% 2rem;
  }
  #login-container {
    margin: 0 15px 4rem 15px;
    #login-container-col {
      width: 450px;
    }
    .submit-btn {
      margin-top: 0;
    }
  }

  .form-control[readonly] {
    background-color: #eceaf1;
    color: #5e576b;
  }

  .alert-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    .alert-svg-icon {
      max-width: 32px;
    }
    .alert {
      margin-bottom: 0;
    }
  }

  .alert-danger {
    .alert-text {
      padding: 1.45rem 0rem 1.45rem 1.25rem;
    }
  }

  .form-group {
    margin-bottom: 0.5rem;

    .valid-input-icon {
      color: #64ba7f;
      opacity: 0;
      margin-right: -32px;
      margin-left: 4px;
    }

    &.valid {
      .valid-input-icon {
        animation: FadeIn 0.2s linear forwards;
        @keyframes FadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }

      .form-control.is-valid, .form-control:valid {
        border: 1px solid #64ba7f;
      }
    }
  }

  .invalid-feedback, .valid-feedback {
    font-size: 85%;
    padding-bottom: 10px;
    display: block;
    min-height: 16.25px; // Allows for a row for an error message without moving other inputs
  }

  .password-show-icon-container {
    color: #91899f;
    background: #f7f6f9;
    border-color: #ebedf0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }

  .feedback-info {
    color: #847b93;
  }

  .checkbox-fieldset {
    .form-group {
      min-height: 17.22px; // Prevents the row from moving on checking/unchecking
    }

    .form-check-inline {
      border: 1px solid white;
      margin-right: 0px;
    }

    input[type=checkbox] {
      filter: hue-rotate(18deg); // Change the native colour of the checkbox
      margin-right: 0;
      height: 15px;
      width: 15px;
      cursor: pointer;
      margin-top: 0;
    }

    .checkbox-feedback {
      margin-top: 0px;
    }
  }
}
