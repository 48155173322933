@import '../Colours.scss';

.form-builder-nav {
  #responsive-navbar-nav {
    justify-content: end;
  }
}

.nav-wide-alert {
  border-radius: 0;
}
