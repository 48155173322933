@import '../Colours.scss';

#accounts-page {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .title-row {
    #accounts-title {
      font-size: 2.3rem;
      margin-bottom: 0;
    }
  }

  .card {
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 20px 2rem;
      .card-title-row {
        height: 44px;
      }
    }
    .card-vis {
      padding-top: 0.5rem;
    }
    .card-bottom-tools {
      padding: 20px 0px 30px 0px;
    }
  }

  .card-table {
    table {
      thead {
        color: #a6a7c1;

        #sortable i {
          margin-left: 4px;
          color: #eaeaea;
          &.sorted {
            color: #696a96;
          }
        }
      }

      thead > tr > th {
        padding-top: 1.21rem;
        padding-bottom: 1.21rem;
        padding-left: 0;
        padding-right: 1.25rem;
        vertical-align: top;
        border-bottom: 0;
        font-size: 1rem;
        font-weight: 600;
        &.link-col {
          width: 1%;
        }
      }

      tbody > tr {
        border-bottom: .07rem dashed #ebedf2;
        font-weight: 500;
      }

      tbody > tr > td {
        color: #9699a2;
        border: 0;
        padding-left: 0;
        vertical-align: top;
        font-size: 1rem;
        &:first-of-type {
          color: #575962;
        }
        &.link-col {
          width: 1%;
          font-weight: 500;
          white-space: nowrap
        }
      }
    }
  }

  .table-search-row {
    input {
      width: 100%;
      max-width: 160px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
    }
    button {
      padding: 7px 14px;
      &:disabled {
        background-color: #00c5dc;
        border-color: #00c5dc;
      }
    }
  }

  .page-item.active .page-link {
    background-color: #5867dd;
    border-color: #5867dd;
  }
  .page-item:not(.disabled):not(.active) .page-link {
    color: #5867dd;
  }

  .pink-pill-btn {
    background-color: #EB2F5C;
    border-color: #EB2F5C;
    color: #fff;
    border-radius: 60px;
    box-shadow: 0 5px 10px 2px rgba(235,47,92,0.19);
    font-size: 1rem;
    &:hover {
      background-color: #931C4B;
    }
  }
}
