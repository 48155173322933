
.segment-comparison-field-table {
  .row > * {
    width: unset;
    max-width: unset;
  }

  .toggle-columns-btn {
    color: #878f97;
    border-color: #878f97;
    &:hover {
      background-color: whitesmoke;
      color: #575962;
      border-color: #575962;
    }
  }

  .paginate-info-row {
    select {
      width: 60px;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    .page-item.active .page-link {
      background-color: #5867dd;
      border-color: #5867dd;
    }
    .page-item:not(.disabled):not(.active) .page-link {
      color: #5867dd;
    }
  }

  .segment {
    box-shadow: 0 1px 8px 1px rgba(150, 150,150,0.3);
    border-radius: 5px;
    border: none;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    height: 100%;
  }

  .segment-config {
    .edit-icons-area {
      display: flex;
      justify-content: space-between;
      .segment-icon {
        opacity: 0.5;
        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
      .segment-icon, .drag-icon {
        margin: 0px 5px 0 5px;
      }

      .drag-icon {
        opacity: 0.4;
        pointer-events: auto !important;
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    .config-area {
      &:not(.editing) {
        padding-bottom: 10px;
      }
      .set-config {
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    p {
      margin: 0;
    }

    h5 {
      font-size: 13.5px;
      margin: 20px 0 5px 0;
      font-weight: 400;
    }

    .segmentConfigFilterList {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      .segmentConfigFilterItem {
        text-align: center;
        min-height: 20px;
        margin-bottom: 5px;
      }
    }

    .attribute-key-value-label {
      max-width: 100%;
      border-radius: 3px;
      letter-spacing: .6px;
      padding: 1px 10px;
      background-color: rgba(143, 137, 214, 0.3);
      font-size: 11.2px;
      line-height: 20px;
      min-height: 20px;
      vertical-align: middle;
      text-align: center;
      display: inline-block;
      color: rgb(68, 68, 68);
    }
  }

  .paginated-table {
    color: #575962;
    overflow: scroll;
    max-height:calc(100vh - 200px); // Max available height minus titles/nav on the page
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
    scroll-behavior: smooth;
    text-align: center;
    min-height: 460px;

    .datatable-search-filter {
      font-weight: 500;
      input {
        width: 100%;
        max-width: 160px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #cccccc;
        border-radius: .25rem;
      }
    }

    .header-group {
      line-height: 18px;
      position: sticky;
      top:0;
      z-index:3;
      background-color: white;
      div > .segment-col:first-of-type {
        margin-left: 1rem;
        margin-right: 0.5rem;
      }
      .segment-col ~ .segment-col {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }

    .header {
      font-weight: bold;
      &.cell {
        border-bottom: 2px solid #dee2e6;
        font-weight: 400;
        font-size: 1.1rem;
        padding: 0;
      }
    }

    .centre-header-with-right-icon {
      background-color: white;
      flex:1;
      display: flex;
      justify-content: center;
      transform: translateX(11px);
      &.order {
        transform: translateX(3px);
      }
    }

    .table-sort-icons {
      background-color: inherit;
      z-index: 2;
      font-size: 1.1rem;
      padding-bottom: 0.5rem;
      .fa-sort-up {
        margin-bottom: -0.4rem;
      }
      .fa-sort-up, .fa-sort-down {
        color:#dcdcdc;
        line-height: 0.5rem;
        &.sorted {
          color: #7a80dd;
        }
      }
    }

    .expand-column-icon {
      cursor: pointer;
      color: #a0a3ab;
    }
    .expand-column-icon svg {
      transform: rotate(45deg);
    }

    .cell {
      &.drag-over {
        background-color: #d0d2d6;
        opacity: 0.5;
      }

      &.drag-completed {
        animation: DragCompleteFadeOut 0.8s ease-out forwards;
      }

      @keyframes DragCompleteFadeOut {
        from {
          background-color: #c6d2f3;
        }
        to {
          background-color: whitesmoke;
        }
      }
    }

    .ellipsis-cell-text {
      overflow: hidden;
      &:not(.expand) {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .table-body {
      .row {
        &:hover {
          .cell {
            background-color: #fafafa;
            &.segment-col {
              color: #404146;
            }
          }
        }

        .cell:not(.segment-col) + .segment-col {
          margin-left: 1rem;
          margin-right: 0.5rem;
        }
        .segment-col + .segment-col {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }
      .cell {
        font-weight: 400;
        font-size: 13px;
        &.segment-col {
          &.data-fetched:empty::before {
            content: 'N/A';
          }
        }
        &.non-segment-col {
          background-color: white;
        }
        text-align: center;
        vertical-align: middle;
        line-height: 18px;
        padding: 5px;
        transition: background-color .2s ease-in-out;
        border-bottom: 1px solid #dee2e6b3;
        word-break: break-word;
      }
    }

    .non-segment-container {
      position: sticky;
      left: 0;
      background: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255, 1) 9%); // Gives fade-out effect on scroll
      z-index: 2;
      .cell {
        position: sticky;
      }
    }

    .segment-container {
      background-color: white;
    }

    .non-segment-col {
      position: sticky;
      z-index: 2;
    }

    .edit-segment-form {
      margin: 10px 10px 0 10px;
      form {
        margin-bottom: 14px;
      }
      .select-label {
        text-align: left;
        display: block;
        margin: 20px 0 5px 0;
        font-size: 13.5px;
        font-weight: 400;
        line-height: 14.85px;
        -moz-osx-font-smoothing: grayscale;
      }
      .edit-segment-filters {
        line-height: 21px;
        font-size: 14px;
        color: #808080;
        font-weight: 400;
      }
    }
  }
  .data-error {
    min-height: 200px;
  }
}

#datatable-column-toggle {
  .popover-arrow {
    display: none;
  }
  .popover-body {
    text-align: start;
    color: #575962;
    padding-bottom: 0px;
    label {
      margin-bottom: 0.5rem;
    }
  }
}
