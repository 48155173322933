@import '../../Colours.scss';

#segment-split {
  background: linear-gradient(to right, #271e3f, #6950a5);
  border-radius: 15px;
  color: white;
  --bs-heading-color: white;
  .card-title {
    font-weight: 400;
    color: white;
  }
  .card-body {
    --bs-card-color: white;
    padding: 20px 1.5rem 20px 1.5rem;
    a {
      color: white;
      text-decoration: underline;
    }
    .alert {
      color: $title_text_grey;
    }
  }

  .card-tagline {
    .significant-diff-highlight {
      padding: 2px 4px;
      background-color: #e05779;
      border-radius: 0.25rem;
      font-weight: 500;
    }
    svg.info {
      color: white;
    }
  }

  .segment-split-inner-card {
    min-height: 140px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 1px #7c64b4;
    .card-body {
      padding: 10px 1.5rem 10px 1.5rem;
    }

    .card-title {
      color: $title_text_grey;
      font-weight: 300;
    }
    transition: all .2s ease-in-out;
    &:hover {
      box-shadow: 0px 0px 6px 4px #7c64b4;
    }

    .progress-area {
      min-height: 140px;
    }
  }

  .segment-split-card-grid {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, max(375px, 32%)), 1fr));
  }

  .strong-text {
    font-weight: 500;
    font-size: 110%;

    &.fade-in {
      animation: fadeIn 500ms ease-in;
    }
  }

  @keyframes fadeIn {
    from { opacity: 0.2; }
    to { opacity: 1; }
  }

  .previous-stat {
    font-size: 92%;
    color: #737582;
    font-weight: 300;
  }

  .loading-placeholder {
    height: 1.3rem;
    vertical-align: middle;
    width: 70px;
    background-color: #e6e6e6;
    border-radius: 2px;
  }

  .skeleton-background {
    animation: skeleton-background-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-background-loading {
    0% {
      background-color: #f9fafb;
    }
    100% {
      background-color: #e1e7ea;
    }
  }
  .skeleton-color {
    animation: skeleton-color-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-color-loading {
    0% {
      color: #e1e7ea;
    }
    100% {
      color: #6C6F7A;
    }
  }

  .text-more-info {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 3px;
    cursor: pointer;
  }

  .segment-split-table {
    border: 1px solid #ebedf3;
    border-radius: 0.25rem;

    .table-responsive {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, .5);
      }
    }

    table {
      --bs-table-hover-bg: #fafafa;

      thead {
        position: sticky;
        top: 0;
        z-index: 3;
      }
      tr {
        height: fit-content;
      }
      tr > th {
        white-space: unset;
        word-break: break-word;
      }
      th {
        font-size: 15px;
        background-color: #fff;
        padding: 0.5rem;
        margin: 0px 3px;
        border-bottom: 2px solid #dee2e6;
      }
      .table-body {
        tr {
          td {
            padding: 10px 7px;
            font-size: 14px;
            border-bottom: 1px solid #ebedf3;
            margin: 0px 3px;
            .progress-bar {
              border-radius: 0.25rem;
              background-color: #846fb8;
            }
            .progress {
              background-color: #f6f5fa;
              cursor: pointer;
              &.highlight-stat {
                .progress-bar {
                  background-color: #e05779;
                }
              }
            }
            .progress-label {
              position: absolute;
              font-size: 12px;
              top: -12px;
              line-height: 10px;
            }
            &:first-of-type {
              .progress-label {
                right: 0;
              }
              .progress {
                justify-content: end;
              }
            }
            &:last-of-type {
              .progress-label {
                left: 0;
              }
              .progress {
                justify-content: start;
              }
            }
          }
          &:hover {
            td {
              background-color: #fafafa;
            }
          }
          &:last-of-type {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  .progress-area {
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }
}

#html-attributes-popover, #info-popover {
  max-width: 350px;
  font-size: 1.1rem;
  .popover-body {
    color: #6C6F7A;
  }

  .significant-diff-highlight {
    font-weight: 500;
    border: 1px solid #e05779;
    padding: 2px 4px;
    border-radius: 0.25rem;
  }
}
