@import '../Colours.scss';

.dashboard {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .title-row {
    margin-bottom: 15px;
    min-height: 39px;
    #dashboard-title {
      font-size: 2.3rem;
      margin-bottom: 0;
      word-break: break-word;
    }
  }

  .btn-less-padding {
    padding: 7px 15px;
  }

  .icon-in-p {
    margin-top: -3px;
  }

  .card {
    &.fav-forms-card {
      border: 1px solid #919cee;
    }
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 0rem 2rem;
      .card-title-row {
        .card-title {
          font-size: x-large;
          color: $title_text_grey;
        }
      }
      .card-vis {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }
    }

    .card-tagline {
      font-weight: 400;
      font-size: 1.1rem;
    }

    .session-usage-stats {
      color: $title_text_grey;
      font-weight: 200;
      font-size: 18px;
    }

    .stats-current {
      font-size: 22px;
      line-height: 18px;
      color: rgb(87, 89, 98);
    }

    .stats-previous {
      font-size: 0.9rem;
      font-weight: 400;
      color: rgb(150, 150, 150);
      min-height: 18.9px;
      width: fit-content;
      margin: auto;

      .caret-down {
        color: #f77783;
      }
      .caret-up {
        color: #64ba7f;
      }
    }

    .text-more-info {
      text-decoration-style: dotted;
      text-decoration-color: #c9c9c9;
      &:hover {
        color: #6c6f7a;
        text-decoration-color: #6c6f7a;
      }
    }

    .tracking-warning-alert {
      margin-bottom: 0;
      min-height: 18.9px;
      width: fit-content;
      padding: 0 0.25rem;
      background-color: rgba(251, 223, 113, 0.24);
      color: #615d5d;

      &.never-tracked {
        background-color: rgba(244, 163, 195, 0.32);
        border-color: #f8bad3;
      }
      a {
        color: #615d5d;
      }
    }
  }

  .form-grid {
    padding-top: 10px;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.33fr));
    margin-bottom: 20px;
    a:hover {
      text-decoration: none;
    }
  }

  .form-summary {
    &:only-child {
      margin-right: 7px;
    }
    min-height: 105px;
    padding: 10px;
    box-shadow: 0 1px 6px 1px rgb(234, 227, 247);
    border-radius: 5px;

    h3 {
      font-size: 24px;
      font-weight: 200;
      color: $title_text_grey;
    }

    .error-msg {
      color: #6C6F7A;
      text-align: center;
    }

    transition-property: color, background-color, box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    &:hover {
      background-color: rgba(240, 235, 249, 0.54);
      box-shadow: 0 1px 6px 1px #e1d7f4;
      cursor: pointer;
      text-decoration: none;
      color: #474849;
      h3 {
        color: #3a3a3b;
      }
    }

    .fav-star-outline {
      color: #d2d3e0;
      transition: all .2s ease-in-out;
      &:hover {
        color: #5867dde6;
      }
    }

    .fav-star {
      color: #5867dde6;
      transition: all .2s ease-in-out;
    }
  }

  .plan-fade-enter {
    opacity: 0;
  }
  .plan-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .plan-fade-exit {
    opacity: 1;
  }
  .plan-fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  .alert-row {
    .alert-svg-icon {
      max-width: 32px;
    }
  }

  .sort-tools-row {
    .form-label {
      font-size: 1.1rem;
    }
  }

  [id^="sort-select-"] {
    min-width: 200px;
  }
}

.dashboard-forms-fade-enter, .dashboard-forms-fade-appearx {
  .form-summary {
    opacity: 0.5;
    h3, p {
      opacity: 0;
    }
    .alert {
      opacity: 0;
      transition: opacity 0ms linear;
    }
  }
}
.dashboard-forms-fade-enter-active, .dashboard-forms-fade-appear-activex {
  h3, p {
    opacity: 1;
    transition: opacity 100ms linear;
  }
  &.form-summary {
    opacity: 1;
    transition: opacity 100ms linear;
  }
  .alert {
    opacity: 1;
    transition: opacity 100ms linear;
  }
}
.dashboard-forms-fade-exit {
  &.form-summary {
    opacity: 0.5;
    h3, p {
      opacity: 0;
    }
    .alert {
      opacity: 0;
      transition: opacity 0ms linear;
    }
  }
}
