@import '../Colours.scss';

#usage-page {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .card {
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 0rem 2rem;
      .card-title-row {
        height: 44px;
      }
    }
    .card-vis {
      padding-top: 0.5rem;
    }
    .card-bottom-tools {
      padding: 20px 0px 30px 0px;
    }
  }

  .card-table {
    table {
      thead {
        color: #a6a7c1;
      }

      thead > tr > th {
        padding-top: 1.21rem;
        padding-bottom: 1.21rem;
        padding-left: 0;
        padding-right: 1.25rem;
        vertical-align: top;
        border-bottom: 0;
        font-size: 1rem;
        font-weight: 600;
        &:last-of-type {
          width: 30%;
        }
      }

      tbody > tr {
        border-bottom: .07rem dashed #ebedf2;
        font-weight: 500;
        &:last-of-type {
          font-weight: 700;
          border-bottom: none;
        }
      }

      tbody > tr > td {
        color: #9699a2;
        border: 0;
        padding-left: 0;
        padding-right: 1.25rem;
        padding-top: 22px;
        vertical-align: top;
        font-size: 1rem;
        &:last-of-type {
          width: 30%;
        }
      }
    }
  }
}
