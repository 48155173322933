.form-builder {
  .element-gallery {
    padding: 10px 15px;
    h3 {
      font-weight: 300;
    }
    h5 {
      border-bottom: 1px solid #d7dbe5;
      padding: 5px 0px;
    }
    .element-grid {
      padding-top: 5px;
      display: grid;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
      .element-item {
        min-height: 50px;
        color: #474849;
        background: linear-gradient(0deg, #e5e2e9, rgb(255, 255, 255) 15%);
        border-radius: 5px;
        border: 1px solid #6c6f7a;
        box-shadow: 0 0 4px 0px rgba(108, 111, 122, 0.3);
        transition-property: box-shadow;
        transition-duration: .5s;
        transition-timing-function: ease-in-out;
        &:hover {
          background: linear-gradient(to top, #d8d4dd, #f5f5f6 15%);
          box-shadow: 0 1px 6px 1px #e1d7f4;
          cursor: pointer;
        }
      }
    }
  }
}
