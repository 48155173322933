.multi-step-progress-bar {
  height: 30px;
  .progress-area {
    display: flex;
    align-items: center;
    height: 100%;
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #17ABA3;
      transition: width 0.3s ease;
      border-radius: 10px;
    }
  }

  .step-numbers-row {
    top: 0;
    .step-number {
      font-weight: 600;
      border-radius: 50%;
      color: white;
      width: 30px;
      height: 30px;
      background-color: #cdd4db;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease;
      &.completed {
        background-color: #17ABA3;
      }
    }
    .step-name {
      position: absolute;
      bottom: 0;
      margin-bottom: -20px;
      color: #7d7f89;
      font-weight: 400;
    }
  }
}