@import '../variables.scss';

#zuko-builder-form {
  .progress-text {
    margin-bottom: 5px;
  }
  .progress-bar-container {
    border-radius: $border-radius;
    background: #f7f7f7;
    border: 1px solid #dedcdc;
    box-shadow: inset 0 0 1px #dedcdc;
    margin-bottom: 15px;
  }

  .progress-bar {
    height: 10px;
    border-radius: $border-radius;
    background-color: var(--accent-color);
    box-shadow: inset 0 3px 6px 3px #583f92; // Default - otherwise overwritten in style attr
    transition: 0.4s linear;
    transition-property: width;
  }
}