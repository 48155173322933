@import 'variables.scss';

#zuko-builder-form {
  .completion {
    animation: fadeIn 400ms ease-in forwards;
    color: $text_grey;
    text-align: center;

    width: 45vw;
    max-width: 800px;
    min-width: 500px;
    padding: 20px 30px;

    h1 {
      margin-top: 20px;
      color: $text_grey;
      font-size: clamp(18px, 7cqw, 1.7em);
      font-weight: bold;
    }

    p {
      font-size: clamp(15px, 5cqw, 1em);
    }
  }
  &.in-edit {
    .completion {
      min-width: unset;
    }
  }
}