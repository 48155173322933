@import '../../Colours.scss';

.form-data {
  // Copies of the charts to fix the height/width for PDF export
  #vsca-chart-copy, #vsca-split-by-attributes-chart-copy, #vsc-rates-chart-copy, #median-duration-chart-copy, #avg-field-returns-chart-copy, #custom-events-total-chart-copy,
    #failed-submissions-chart-copy {
    display: none !important;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .granularity-buttons {
    color: $dark_text_grey;

    label {
      text-transform: capitalize;
    }
    p {
      margin: 0 0.75rem 0 0.5rem;
    }
  }

  .card {
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 0rem 2rem;
      .card-title-row {
        .col {
          min-height: 44px;
          display: flex;
          align-items: center;
        }
      }
      .card-vis {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }
    }
  }

  .vsc-rates-flip-card {
    max-height: 570px;
  }

  #vsca-split-by-attr {
    .card-title {
      max-width: 450px;
      margin: auto;
    }
    .scrollable-legend {
      max-height: 150px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, .5);
      }
    }

    .vis-select-area {
      padding: 0 60px;
    }

    #vsca-split-metric-select, #vsca-split-attr-key-select {
      min-width: 150px;
    }

    .quick-tip-position {
      top: 4px;
      right: -60px;
    }
  }

  .progress-area {
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  .card-stats {
    color: #575962;
    p, .percent-diff  {
      font-size: 12px;
    }
    .stats-current {
      font-size: 16px;
      line-height: 18px;
    }
    .stats-previous {
      font-size: 0.9rem;
      font-weight: 400;
      color: #969696;
    }
    .text-more-info {
      text-decoration: underline;
      text-decoration-style: dotted;
      text-decoration-color: #c9c9c9;
      &:hover {
        color: #6c6f7a;
        text-decoration-color: #6c6f7a;
      }
    }

    .wide-stat {
      padding-left: 5px;
      padding-right: 5px;
      p {
        display: inherit;
      }
    }
  }

  .replay-link-grid {
    grid-template-columns: 1fr auto 1fr;

    .grid-item {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .video-icon-wrap {
      display: flex;
      width: fit-content;
    }
  }

  #vsca {
    .stat-label {
      width: 100%
    }
    .stat-label::first-letter {
      text-transform: capitalize;
    }
  }

  .flip-card {
    .card {
      background-color: transparent;
    }
    perspective: 1000px;
    background-color: transparent;
    margin-bottom: 2.2rem;

    .card-body {
      min-height: 491px;
      z-index: -1;
    }

    #custom-events-total {
      .card-body {
        min-height: 445px;
      }
    }

    .card-tooltip {
      max-width: fit-content;
      .info-circle-icon {
        cursor: pointer;
        color: #a291ca;
        transition: all .2s ease-in-out;
        &:hover {
          color: #6950a5;
          transform: scale(1.1);
        }
      }
    }

    .flip-card-inner {
      transition: transform 0.6s;
      transform-style: preserve-3d;
        &.perform-flip  {
          transform: rotateX(180deg);
        }
      }

    .flip-card-front, .flip-card-back {
      min-width: 100%;
    }

    .flip-card-front {
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: white;
      padding: 20px 2rem 0rem 2rem;
      transform: rotateX(0deg);
      opacity: 1;
      z-index: 2;
      &.not-visble {
        animation: fadeOut 0.2s linear forwards;
      }
      &.visible {
        z-index: 2;
        animation: fadeIn 0.2s linear forwards;
      }
    }

    .flip-card-back {
      position: absolute;
      bottom: 0;
      background: linear-gradient(to right, #ffc95f, #ee345d, #c22959, #6950a5);
      padding: 2px;
      -moz-transform: rotateX(180deg);
      transform: rotateX(180deg);
      height: 100%;
      &.not-visble {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        animation: fadeOut 0.3s linear forwards;
      }
      opacity: 0;
      &.visible {
        animation: fadeIn 0.3s ease-in forwards;  // Animation (as opposed to transition) improves the flip in Chrome as we're having to set z-index
        z-index: 2; // Required for links to be clickable in Chrome
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    .card-contents {
      background-color: white;
      padding: 18px 26px 22px 26px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .text-content {
        overflow-y: auto;
          &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 4px;
        }
          &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: rgba(0, 0, 0, .5);
        }
      }
      .subtitle {
        font-weight: 500;
      }
      .card-info-img {
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }
      .card-tip-box {
        position: sticky;
        top: 0;
        border: 1px solid #e3e8ee;
        padding: 20px;
        h4 {
          font-weight: 500;
          font-size: 1.3rem;
        }
      }
    }

    #vsca {
      #VSCA-chart-points {
        height: auto;
        width: 75%;
        max-width: 350px;
      }
      #VSCA-stats {
        height: auto;
        width: 90%;
        max-width: 400px;
      }
    }
    #Custom-Events-Overview-Session-Split {
      height: auto;
      width: 90%;
      max-width: 400px;
    }
  }

  .pdf-export-icon, .png-download-icon {
    margin-right: 7px;
  }
  .url-copy-icon {
    margin-right: 5px;
  }

  #datepicker {
    min-width: 30%;
  }

  .submit-field-select {
    width: 300px;
  }

  #completion-path {
    .card-body {
      min-height: 640px;
    }
    .card-vis {
      padding-bottom: 0;
    }
    .card-tagline {
      font-weight: 400;
      font-size: 1.1rem;
    }

    .grid-scrollable-area {
      overflow: scroll;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, .5);
      }
    }

    #session-flows-chart-grid {
      margin-top: 10px;
      margin-bottom: 10px;
      display: grid;
      grid-template-rows: repeat(7, 50px);

      .stat-label {
        font-size: clamp(12px, 1.4vw, 16px);
      }
      .stat-number {
        font-size: clamp(14px, 1.7vw, 20px);
        font-weight: 500;
      }

      .completion-path-arrow {
        max-width: 127px;
        min-width: 90px;
      }
      .wide-cell {
        min-width: 100px;
      }

      .stat-percent {
        font-size: 14px;
        padding: 2px;
        border-radius: 0.25rem;
        font-weight: 400;
      }
      .arrow-overlay-top, .arrow-overlay-bottom {
        .stat-number:not(:empty) {
          background-color: rgba(255, 255, 255, 0.96);
          padding: 2px;
          border-radius: 0.25rem;
          font-size: clamp(9px, 1vw, 13px);
        }
      }
      .arrow-overlay-top {
        margin-top: 40px;
        margin-left: 40px;
      }
      .arrow-overlay-bottom {
        margin-top: -90px;
        margin-left: 40px;
      }
      .views-cell {
        grid-row-start: 4;
        grid-column-start: 1;
      }
      .arrow-cell-1 {
        grid-row-start: 3;
        grid-row-end: 7;
        grid-column-start: 2;
      }
      .arrow-cell-1-top, .arrow-cell-1-bottom {
        .stat-number:not(:empty) {
          color: #F77F5E;
          border: 1px solid #F77F5E;
        }
      }
      .arrow-cell-1-top {
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 2;
      }
      .arrow-cell-1-bottom {
        grid-row-start: 7;
        grid-row-end: 8;
        grid-column-start: 2;
      }
      .starters-cell {
        grid-row-start: 3;
        grid-column-start: 3;
      }
      .non-starters-cell {
        margin-top: 30px;
        grid-row-start: 6;
        grid-column-start: 3;
      }
      .arrow-cell-2 {
        grid-row-start: 2;
        grid-row-end: 6;
        grid-column-start: 4;
      }
      .arrow-cell-2-top, .arrow-cell-2-bottom {
        .stat-number:not(:empty) {
          color: #e5486a;
          border: 1px solid #e5486a;
        }
      }
      .arrow-cell-2-top {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 4;
      }
      .arrow-cell-2-bottom {
        grid-row-start: 6;
        grid-row-end: 7;
        grid-column-start: 4;
      }
      .submit-attempted-cell {
        grid-row-start: 2;
        grid-column-start: 5;
      }
      .abandoned-before-cell {
        margin-top: 30px;
        grid-row-start: 5;
        grid-column-start: 5;
      }
      .arrow-cell-3 {
        grid-row-start: 1;
        grid-row-end: 5;
        grid-column-start: 6;
      }
      .arrow-cell-3-top, .arrow-cell-3-bottom {
        .stat-number:not(:empty) {
          color: #C22959;
          border: 1px solid #C22959;
        }
      }
      .arrow-cell-3-top {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 6;
      }
      .arrow-cell-3-bottom {
        grid-row-start: 5;
        grid-row-end: 6;
        grid-column-start: 6;
      }
      .submit-success-cell {
        grid-row-start: 1;
        grid-column-start: 7;
      }
      .submit-failed-cell {
        grid-row-start: 4;
        grid-column-start: 7;
      }
      .arrow-cell-4 {
        grid-row-start: 3;
        grid-row-end: 7;
        grid-column-start: 8;
      }
      .arrow-cell-4-top, .arrow-cell-4-bottom {
        .stat-number:not(:empty) {
          color: #6950A5;
          border: 1px solid #6950A5;
        }
      }
      .arrow-cell-4-top {
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 8;
      }
      .arrow-cell-4-bottom {
        grid-row-start: 7;
        grid-row-end: 8;
        grid-column-start: 8;
      }
      .re-submit-success-cell {
        grid-row-start: 3;
        grid-column-start: 9;
      }
      .submit-abandon-cell {
        margin-top: 30px;
        grid-row-start: 6;
        grid-column-start: 9;
      }

      .lost-opportunity-cell-figure {
        width: fit-content;
        margin: auto;
        border-radius: 0.25rem;
        padding: 2px 4px;
        border: 1px solid #f2af7bde;
        color: #F0764A;
        margin-top: 3px;
        .stat-label {
          font-size: clamp(10px, 1.4vw, 14px);
        }
        .stat-number {
          font-size: clamp(12px, 1.5vw, 16px);
        }
      }
    }

    .completion-value-container {
      width: fit-content;
      border-top: 2px solid #ebedf2;
      padding-top: 5px;

      #completion-currency {
        max-width: 60px;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23a0a3ab%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
        background-color: #f5f5f5;
        color: #6c6f7a;
        transition: all .2s ease-in-out;
        &:hover {
          color: #3f4047;
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%233f4047%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
        }
      }

      #completion-value {
        max-width: 100px;
      }

      .btn-less-padding {
        padding: 6px 12px;
      }
      .alert {
        width: fit-content;
        .alert-text {
          font-size: 1.1rem;
        }
        .btn-close, .alert-text {
          padding: 5px 8px;
        }
      }

      .label-info-icon {
        vertical-align: top;
        margin-left: 2px;
        color: #858893;
        &:hover {
          color: #6c6f7a;
        }
      }
      .currency-select {
        height: calc(1.5em + 0.75rem + 2px);
      }
    }

    .card-stats {
      .stat-number {
        font-size: 16px;
      }
    }

    .flip-card-back {
      #Completion-Path-Img {
        height: auto;
        width: 90%;
      }
      h4 {
        font-weight: 500;
        font-size: 1.3rem;
      }
    }
  }
}

// Align card height on narrow width
#vsca-split-by-attr, #vsc-rates, #median-session-duration, #avg-field-returns {
  height: 94%
}

#scrollable-custom-events {
  max-height: 351px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
  }
}