@import '../variables.scss';

#zuko-builder-form {
  .form-card:has(.form-in-edit) {
    .paragraph-text {
      margin-bottom: 10px;
    }
  }

  form {
    accent-color: var(--accent-color);

    .fields-container {
      padding-bottom: 2px;
    }

    .step:not(.form-wrapper.first-question .step) {
      &.fade-in {
        animation: formFadeIn 400ms ease-in forwards;
      }
    }

    .title {
      margin: 20px 0;
    }
  }

  @keyframes formFadeIn {
    from { opacity: 0.2; }
    to { opacity: 1; }
  }

  mark, .mark {
    padding: 0;
    color: unset;
    background-color: unset;
  }

  .form-in-edit {
    cursor: pointer;
    position: relative;
    input[type="text"], input[type="radio"], input[type="checkbox"], input[type="email"], input[type="number"], input[type="tel"],
    textarea:not(#bulk-options) {
      pointer-events: none;
    }

    &:not(.no-content) {
      box-shadow: 0 0 1px 1px #d6ecef;
    }
    border-radius: 5px;

    mark, .mark {
      text-decoration: underline;
      text-decoration-style: dashed;
      text-underline-offset: 3px;
      text-decoration-color: transparent;
      text-decoration-thickness: 1px;
      cursor: text;
    }

    .field {
      position: relative;
      margin-bottom: 15px;
      &:has(.single-checkbox), &:has(.radio-fieldset), &:has(.address-fieldset), &:has(.checkbox-group-fieldset) {
        margin-bottom: 0;
      }
      label+.edit-inline-icon, legend+.edit-inline-icon,
      .checkbox-group .checkbox-item:not(.other-option) .delete-option,
      .radio-button-group .radio-button-option:not(.other-option) .delete-option {
        margin-bottom: 5px;
      }
      .single-checkbox {
        label+.edit-inline-icon {
          margin-bottom: 0px;
        }
      }
      .question-hint+.edit-inline-icon {
        padding-bottom: 2px;
      }

      .bulk-options-group {
        label {
          min-height: unset;
        }
        label {
          font-size: 0.9rem;
          color: #6e707c;
        }
        .invalid-input-feedback {
          width: 100%;
          margin-top: 0.25rem;
          font-size: 80%;
          color: #dc3545;
        }
      }

      #bulk-options {
        cursor: text;
        height: fit-content;
        max-height: 220px; // Around 10 rows high
        min-height: 54px; // Around 2 rows high

        &.invalid-input {
          border-color: #dc3545;
          &:focus {
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          }
        }
      }
    }

    &.question {
      padding: 5px 15px 1px 15px;
      margin: 10px -15px -1px -15px;
      &:hover, &:focus, &:has(.editable-inline:hover), &:focus, &:has(.editable-inline:focus) {
        transition: all 100ms linear;
        border-radius: $border-radius;
        box-shadow: 0 0 2px 1px #00B3C7, 0 0 10px 0px rgba(141, 131, 166, 0.3);
      }
      &:has(.single-checkbox) {
        padding-bottom: 10px;
      }
    }
    &.paragraph-text {
      .up-and-down-icons {
        top: 0;
        .up-icon-container {
          margin-bottom: 2px;
        }
        .down-icon-container {
          margin-top: 2px;
        }
      }
    }
    &.form-description:not(.no-content), &.step-description:not(.no-content), &.paragraph-text {
      padding: 10px 15px 1px 15px;
      margin: 10px -15px -1px -15px;
      &:hover, &:focus, &:has(.editable-inline:hover), &:focus, &:has(.editable-inline:focus) {
        transition: all 100ms linear;
        border-radius: $border-radius;
        box-shadow: 0 0 2px 1px #00B3C7, 0 0 10px 0px rgba(141, 131, 166, 0.3);
      }
    }
    &.step-title, &.form-title, &.completion-title, &.completion-message {
      &:hover, &:has(.editable-inline:hover), &:focus, &:has(.editable-inline:focus) {
        transition: all 100ms linear;
        border-radius: $border-radius;
        box-shadow: 0 0 2px 1px #00B3C7, 0 0 10px 0px rgba(141, 131, 166, 0.3);
      }
    }
    &.step-title, &.form-title, &.completion-title {
      padding: 0px 15px 0px 15px;
      margin: 0px -15px 0px -15px;
    }
    &.completion-message {
      padding: 10px 15px 1px 15px;
      margin: 10px -15px -1px -15px;
    }

    &.step-description-in-edit, &.form-description-in-edit  {
      min-height: 24px;
    }

    .delete-option {
      opacity: 0.6;
    }
    .option-button {
      opacity: 0.4;
      &:hover {
        background-color: #00B3C7;
        color: white;
      }
    }

    .delete-icon-container, .configure-icon-container {
      opacity: 0.4;
      position: absolute;
      transition: all 100ms linear;
      .configure-icon, .delete-icon {
        transition: all 100ms linear;
        margin: 5px 5px 5px 7px;
        color: #00B3C7;
      }
    }
    .delete-icon-container, .configure-icon-container {
      background-color: white;
      border-radius: $border-radius;
      border: 1px solid #00B3C7;
      &:hover {
        background-color: #00B3C7;
        .configure-icon, .delete-icon {
          color: white;
        }
      }
    }

    .delete-icon-container {
      right: -80px;
      top: 0px;
    }

    .configure-icon-container {
      right: -40px;
      top: 0px;
    }

    .up-and-down-icons {
      opacity: 0.4;
      position: absolute;
      left: -24px;
      .up-icon-container, .down-icon-container {
        transition: all 100ms linear;
        background-color: white;
        border-radius: $border-radius;
        border: 1px solid #00B3C7;
        .up-icon, .down-icon {
          transition: all 100ms linear;
          margin: 5px 7px 5px 5px;
          color: #00B3C7;
        }
        &:hover {
          background-color: #00B3C7;
          .up-icon, .down-icon {
            color: white;
          }
        }
      }

      .up-icon-container {
        margin-bottom: 5px;
      }

      .down-icon-container {
        margin-top: 5px;
      }
    }

    &:hover, &.question-in-edit, &.step-title-in-edit, &.form-title-in-edit, &.form-description-in-edit, &.step-description-in-edit, &.paragraph-text-in-edit,
    &.completion-title-in-edit, &.completion-message-in-edit, &:focus, &:has(.editable-inline:hover), &:focus, &:has(.editable-inline:focus) {
      .delete-icon-container, .up-and-down-icons, .configure-icon-container, .option-button, .delete-option {
        transition: all 100ms linear;
        opacity: 1;
      }
      &:has(.editable-inline.in-error) {
        box-shadow: 0 0 2px 1px #c70038, 0 0 10px 0px rgba(166,131,137, 0.3%);
      }

      mark, .mark {
        transition: text-decoration-color 200ms linear;
        text-decoration-color: #999999;
      }
      .edit-inline-icon {
        opacity: 1;
      }
    }

    // We need to use disabled selects - just not the disabled background
    div[class$="control"] {
      background-color: white;
    }

    &.question-in-edit, &.step-title-in-edit, &.form-title-in-edit, &.form-description-in-edit, &.step-description-in-edit, &.paragraph-text-in-edit,
    &.completion-title-in-edit, &.completion-message-in-edit {
      transition: all 100ms linear;
      border-radius: $border-radius;
      box-shadow: 0 0 2px 1px #00B3C7, 0 0 10px 0px rgba(141, 131, 166, 0.3);
    }
    &:has(.editable-inline.in-error) {
      box-shadow: 0 0 2px 1px #c70038, 0 0 10px 0px rgba(166,131,137, 0.3%);
    }

    .delete-option {
      cursor: pointer;
      color: #70717a;
      &:hover {
        color: $text_black;
      }
    }
  }
}